import React from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";

const Blank = () => {
  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            Breadcrumb
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <p>Blank page</p>
            <p>
              Loremipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
              et finibus eros. Donec in lorem mi. Class aptent taciti sociosqu
              ad litora torquent per conubia nostra, per inceptos himenaeos.
              Nulla id turpis mi. Mauris elementum nunc volutpat, venenatis
              velit non, faucibus nisi. Suspendisse vel sem augue. Sed nisi
              turpis, ultrices sed efficitur vitae, maximus et purus. Proin
              viverra luctus condimentum. Nulla facilisi. Nulla sit amet nunc in
              quam faucibus consequat. Aliquam euismod sem a erat elementum, non
              dignissim eros bibendum. In lobortis est id lacinia feugiat. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos. Praesent dolor leo, sollicitudin ac egestas
              id, venenatis ac massa. Cras dictum ultricies eros, vel consequat
              libero mattis fringilla. Integer eleifend lectus sed posuere
              dictum. Sed pellentesque ultricies nunc vel pharetra. Vivamus
              justo lectus, pharetra nec turpis a, eleifend laoreet tellus.
              Nulla leo nisl, pharetra vitae lectus eu, fringilla accumsan
              velit. Vestibulum et risus egestas, consequat eros elementum,
              lobortis elit. Aliquam ut mollis lacus. Pellentesque bibendum ex
              eu augue convallis, vel euismod mi cursus. Mauris ornare odio quis
              magna ultricies, a tempus dui fringilla. Aenean lacus nunc,
              consequat et risus id, blandit pharetra metus. Curabitur quis
              porta quam, sed eleifend sapien. Donec consectetur placerat leo et
              pretium. Pellentesque dignissim turpis vel nulla commodo
              ultricies. Ut leo velit, interdum a tortor nec, porttitor vehicula
              diam. Integer orci justo, iaculis eu volutpat et, eleifend quis
              nisl. In sit amet lectus justo. Maecenas id facilisis tellus.
              Maecenas vestibulum malesuada eros, ac dignissim ligula molestie
              vehicula. Aenean imperdiet urna quam, tempus placerat lectus
              venenatis a. Praesent vel ex fermentum, luctus neque ut, tempor
              ex. Curabitur fermentum vestibulum quam et hendrerit. Vestibulum
              eget ultrices orci, vel eleifend urna. Nam dignissim massa nec
              porttitor vulputate. Integer pharetra ante ut mi bibendum
              hendrerit. Nunc dapibus, mi non malesuada suscipit, arcu diam
              viverra sem, quis molestie sem erat sit amet nibh. Fusce mauris
              nibh, iaculis vel tellus nec, volutpat pulvinar nibh. Donec
              egestas rutrum nisl. Etiam non mauris quis ante convallis
              efficitur quis sed felis. Donec mollis dignissim scelerisque. Duis
              vel aliquet velit. Vivamus molestie purus at nisl dignissim, non
              pharetra arcu lobortis. Mauris vitae leo sit amet nisl aliquet
              fermentum. Donec vel purus sit amet est tempus aliquam non eu
              turpis.
            </p>
          </Colxx>
        </Row>
      </div>
    </AppLayout>
  );
};

export default Blank;
