const data = [
  {
    id: "dashboard",
    icon: "iconsminds-check",
    label: "Dashboard",
    to: `/app/dashboard`,
    access: ["Admin", "Dekanat", "Tutor", "Foydalanuvchi"],
  },
  {
    id: "students",
    icon: "iconsminds-student-hat",
    label: "Talabalar",
    to: `/app/students`,
    access: ["Admin", "Dekanat", "Tutor", "Foydalanuvchi"],
  },
  {
    id: "balance",
    icon: "iconsminds-printer",
    label: "Yoshlar Balansi",
    to: `/app/balance`,
    access: ["Admin", "Tutor", "Dekanat", "Foydalanuvchi"],
  },
  {
    id: "users",
    icon: "iconsminds-conference",
    label: "Foydalanuvchilar",
    to: `/app/users`,
    access: ["Admin"],
  },
];
export default data;
