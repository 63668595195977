import { CHART_COLORS, CHART_COLORS_ALPHA } from "./Constants";

export const socialDataConstruct = (data) => {
  const titles = [
    "Kam ta'minlangan oila farzandlari (IHYaR)",
    "Temir daftardagi oila farzandlari",
    "Yoshlar daftariga kiritilganlar",
    "Ayollar daftariga kiritilgan ona farzandlari",
    "Og'ir ijtimoiy holatga tushib qolgan oila farzandlari",
    "Mehr daftariga kiritilgan talabalar",
    "Imtiyoz asosida o'qishga qabul qilinganlar",
    "Muddatli harbiy xizmatdan qaytganlar",
    "Nafaqa oluvchilar",
    "Ishlaydigan talabalar",
    "Farzandli talabalar",
    "Ota-onasi ota-onalik huquqidan mahrum etilganlar",
    "Mehribonlik uyi va Bolalar shaharchasida tarbiyalangan yoshlar soni",
    "Nogironligi bo'lganlar",
    "Toifalari bo'yicha",
    "Yetim talabalar soni",
  ];

  let lowIncomeFamily = data.filter((one) => one.lowIncomeFamily);
  let ironNotebook = data.filter((one) => one.ironNotebook);
  let inYouthBook = data.filter((one) => one.inYouthBook);
  let motherInYouthBook = data.filter((one) => one.motherInYouthBook);
  let mercyBook = data.filter((one) => one.mercyBook);
  let familyInHeavySocialStatus = data.filter(
    (one) => one.familyInHeavySocialStatus
  );
  let withPrivilage = data.filter((one) => one.withPrivilage);
  let returnedMilitary = data.filter((one) => one.returnedMilitary);
  let pensioner = data.filter((one) => one.pensioner);
  let isWorking = data.filter((one) => one.isWorking);
  let hasChild = data.filter((one) => one.hasChild);
  let noParentalRights = data.filter((one) => one.noParentalRights);
  let fromOrphanage = data.filter((one) => one.fromOrphanage);
  let byCategories = data.filter(
    (one) =>
      one.weakSight ||
      one.movementInjured ||
      one.weakDeaf ||
      one.blind ||
      one.deaf ||
      one.speechDefect ||
      one.otherDisabled
  );
  let disabled = data.filter(
    (one) =>
      one.disabled !== null && one.disabled !== "" && one.disabled !== undefined
  );
  let orphan = data.filter(
    (one) =>
      one.orphan !== null && one.orphan !== "" && one.orphan !== undefined
  );

  let resultData = [
    lowIncomeFamily.length,
    ironNotebook.length,
    inYouthBook.length,
    motherInYouthBook.length,
    familyInHeavySocialStatus.length,
    mercyBook.length,
    withPrivilage.length,
    returnedMilitary.length,
    pensioner.length,
    isWorking.length,
    hasChild.length,
    noParentalRights.length,
    fromOrphanage.length,
    disabled.length,
    byCategories.length,
    orphan.length,
  ];
  let resultWomenData = [
    lowIncomeFamily.filter((one) => one.isFemale).length,
    ironNotebook.filter((one) => one.isFemale).length,
    inYouthBook.filter((one) => one.isFemale).length,
    motherInYouthBook.filter((one) => one.isFemale).length,
    familyInHeavySocialStatus.filter((one) => one.isFemale).length,
    mercyBook.filter((one) => one.isFemale).length,
    withPrivilage.filter((one) => one.isFemale).length,
    returnedMilitary.filter((one) => one.isFemale).length,
    pensioner.filter((one) => one.isFemale).length,
    isWorking.filter((one) => one.isFemale).length,
    hasChild.filter((one) => one.isFemale).length,
    noParentalRights.filter((one) => one.isFemale).length,
    fromOrphanage.filter((one) => one.isFemale).length,
    disabled.filter((one) => one.isFemale).length,
    byCategories.filter((one) => one.isFemale).length,
    orphan.filter((one) => one.isFemale).length,
  ];

  let simpleData = [];
  let labels = [];
  for (let i = 0; i < titles.length; i++) {
    labels.push(i + 1);
    simpleData.push({
      title: titles[i],
      total: resultData[i],
      women: resultWomenData[i],
    });
  }

  return {
    data: simpleData,
    chartData: {
      labels: labels,
      datasets: [
        {
          data: resultData,
          label: "Jami",
          borderColor: CHART_COLORS.blue,
          fill: false,
          tension: 0.3,
        },
        {
          data: resultWomenData,
          label: "Ayollar",
          borderColor: CHART_COLORS.red,
          fill: false,
          tension: 0.3,
        },
      ],
      titles: titles,
    },
  };
};
