import React from "react";

import TopNav from "containers/navs/Topnav";
import Sidebar from "containers/navs/Sidebar";
import Footer from "containers/navs/Footer";

const AppLayout = ({ children }) => {
  return (
    <div id="app-container">
      <TopNav />
      <Sidebar />
      <main>
        <div className="container-fluid">{children}</div>
      </main>
    </div>
  );
};

export default AppLayout;
