import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import { Button, Row } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";

const Balance = () => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [token, setToken] = useToken();
  const user = useUser();

  useEffect(() => {
    handleDownload();
  }, []);

  const handleDownload = () => {
    setDownloadLoading(true);
    axios
      .post(
        `${
          user.role === "Tutor"
            ? `/api/download/balance/${user.username}`
            : user.role === "Dekanat"
            ? `/api/download/dean-balance/${user.username}`
            : "/api/download/balance"
        }`,
        { title: "Balans" },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "Yoshlar Balansi.xlsx"; //or any other extension
        document.body.appendChild(link);
        link.click();
        setDownloadLoading(false);
      });
  };

  return (
    <AppLayout>
      <div className="loading" hidden={!downloadLoading} />
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <h1>Yoshlar Balansi</h1>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <p>
              {downloadLoading
                ? "Yoshlar Balansi excel formatda tayyorlanmoqda"
                : "Excel fayl qurilmangizga yuklandi! Foydalanishingiz mumkin."}
            </p>

            <Button
              color="primary"
              onClick={handleDownload}
              disabled={downloadLoading}
            >
              {downloadLoading ? "Yuklanmoqda..." : "Qaytadan yuklash"}
            </Button>
          </Colxx>
        </Row>
      </div>
    </AppLayout>
  );
};

export default Balance;
