import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { PolarAreaChart } from "components/charts";
import BarChartBorderRadius from "components/charts/BarChartBorderRadius";

const CategoriesBarChart = ({
  chartClass = "chart-container",
  title,
  data,
  child,
}) => {
  return (
    <Card>
      <CardBody>
        <CardTitle>{title}</CardTitle>
        <div className={"mb-5"}>
          <BarChartBorderRadius data={data.chartData} />
        </div>
        <div>{child}</div>
      </CardBody>
    </Card>
  );
};

export default CategoriesBarChart;
