import { CHART_COLORS, CHART_COLORS_ALPHA } from "./Constants";

export const talentedDataConstruct = (data) => {
  const titles = [
    "Davlat mukofoti bilan taqdirlanganlar",
    "Ko'krak nishonlari bilan taqdirlanganlar",
    "Prezident stipendiyasi sohiblari",
    "Davlat stipendiyalari sohiblari",
    "Xalqaro stipendiyalar sohiblari",
    "Respublika sport musobaqalari g'oliblari",
    "Xalqaro sport musobaqalari g'oliblari",
    "Respublika fan olimpiadalari g'oliblari",
    "Xalqaro fan olimpiadalari g'oliblari",
    "Boshqa yutuq va imtiyozlar",
  ];

  let governmentAward = data.filter((one) => one.governmentAward);
  let badgeAward = data.filter((one) => one.badgeAward);
  let presidentScholarship = data.filter((one) => one.presidentScholarship);
  let governmentScholarship = data.filter((one) => one.governmentScholarship);
  let internationalScholarship = data.filter(
    (one) => one.internationalScholarship
  );
  let republicSport = data.filter((one) => one.republicSport);
  let internationalSport = data.filter((one) => one.internationalSport);
  let republicScienceOlymp = data.filter((one) => one.republicScienceOlymp);
  let internationalScienceOlimp = data.filter(
    (one) => one.internationalScienceOlimp
  );
  let otherAwards = data.filter((one) => one.otherAwards);

  let resultData = [
    governmentAward.length,
    badgeAward.length,
    presidentScholarship.length,
    governmentScholarship.length,
    internationalScholarship.length,
    republicSport.length,
    internationalSport.length,
    republicScienceOlymp.length,
    internationalScienceOlimp.length,
    otherAwards.length,
  ];

  let resultWomenData = [
    governmentAward.filter((one) => one.isFemale).length,
    badgeAward.filter((one) => one.isFemale).length,
    presidentScholarship.filter((one) => one.isFemale).length,
    governmentScholarship.filter((one) => one.isFemale).length,
    internationalScholarship.filter((one) => one.isFemale).length,
    republicSport.filter((one) => one.isFemale).length,
    internationalSport.filter((one) => one.isFemale).length,
    republicScienceOlymp.filter((one) => one.isFemale).length,
    internationalScienceOlimp.filter((one) => one.isFemale).length,
    otherAwards.filter((one) => one.isFemale).length,
  ];

  let simpleData = [];
  let labels = [];
  for (let i = 0; i < titles.length; i++) {
    labels.push(i + 1);
    simpleData.push({
      title: titles[i],
      total: resultData[i],
      women: resultWomenData[i],
    });
  }

  return {
    data: simpleData,
    chartData: {
      labels: labels,
      datasets: [
        {
          data: resultData,
          label: "Jami",
          borderColor: CHART_COLORS.blue,
          fill: false,
          stepped: true,
        },
        {
          data: resultWomenData,
          label: "Ayollar",
          borderColor: CHART_COLORS.red,
          fill: false,
          stepped: true,
        },
      ],
      titles: titles,
    },
  };
};
