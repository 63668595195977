import React from "react";
import { Card, CardBody, CardTitle, Row } from "reactstrap";
import LineChartInterpolation from "components/charts/LineChartInterpolation";

const CategoriesLineInterpolation = ({
  chartClass = "chart-container",
  title,
  data,
  child,
}) => {
  let dataP = [];
  if (data.titles) {
    data.titles.forEach((element, index) => {
      dataP.push(
        <span>
          {index + 1}. {element}
          <br />
        </span>
      );
    });
  }

  return (
    <Card>
      <CardBody>
        <CardTitle>{title}</CardTitle>
        <div className={"mb-5"}>
          <LineChartInterpolation data={data.chartData} />
        </div>
        <Row>{child}</Row>
      </CardBody>
    </Card>
  );
};

export default CategoriesLineInterpolation;
