import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { useNavigate } from "react-router-dom";
import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import IconCard from "components/cards/IconCard";
import GlideComponent from "components/carousel/GlideComponent";
import { Button, Row } from "reactstrap";
import { Colxx } from "components/common/CustomBootstrap";
import ProfileStatuses from "containers/dashboards/ProfileStatuses";
import { livingDataConstruct } from "utils/livingDataConstruct";
import { marriageDataConstruct } from "utils/marriageDataConstruct";
import { socialDataConstruct } from "utils/socialDataConstruct";
import { talentedDataConstruct } from "utils/talentedDataConstruct";
import { moneyAwardedDataConstruct } from "utils/moneyAwardedDataConstruct";
import { specialControlDataConstruct } from "utils/specialControlDataConstruct";
import CategoriesBarChart from "containers/dashboards/CategoriesBarChart";
import ProfileStatusesSpan from "containers/dashboards/ProfileStatusesSpan";
import CategoriesLineInterpolation from "containers/dashboards/CategoriesLineInterpolation";
import CategoriesSteppedLine from "containers/dashboards/CategoriesSteppedLine";
import CategoriesLineDrawTime from "containers/dashboards/CategoriesLineDrawTime";
import CategoriesRadar from "containers/dashboards/CategoriesRadar";

const MainDashboard = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [livingData, setLivingData] = useState([]);
  const [marriageData, setMarriageData] = useState([]);
  const [socialData, setSocialData] = useState([]);
  const [talentedData, setTalentedData] = useState([]);
  const [moneyAwardedData, setMoneyAwardedData] = useState([]);
  const [specialControlData, setSpecialControlData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [token, setToken] = useToken();
  const user = useUser();

  // eslint-disable-next-line no-extend-native
  Array.prototype.belowThirties = function () {
    let result = [];
    for (let i = 0; i < this.length; i++) {
      let one = this[i];
      let today = new Date();
      if (
        new Date(one.dob) >
        new Date(today.getFullYear() - 30, today.getMonth(), today.getDate())
      ) {
        result.push(one);
      }
    }
    return result;
  };

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    axios
      .get(
        `${
          user.role === "Tutor"
            ? `/api/students/${user.username}`
            : user.role === "Dekanat"
            ? `/api/dean-students/${user.username}`
            : "/api/active-students"
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        if (isMounted) {
          setData(result.data.data);
          let belowThirtyData = result.data.data.belowThirties();
          setLivingData(livingDataConstruct(belowThirtyData));
          setMarriageData(marriageDataConstruct(belowThirtyData));
          setSocialData(socialDataConstruct(belowThirtyData));
          setTalentedData(talentedDataConstruct(belowThirtyData));
          setMoneyAwardedData(moneyAwardedDataConstruct(belowThirtyData));
          setSpecialControlData(specialControlDataConstruct(belowThirtyData));
          setLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const handleDownload = () => {
    setDownloadLoading(true);
    axios
      .post(
        `${
          user.role === "Tutor"
            ? `/api/download/balance/${user.username}`
            : "/api/download/balance"
        }`,
        { title: "Balans" },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "Yoshlar Balansi.xlsx"; //or any other extension
        document.body.appendChild(link);
        link.click();
        setDownloadLoading(false);
      });
  };

  return (
    <AppLayout>
      <div className="loading" hidden={!loading} />
      {!loading && (
        <div className="dashboard-wrapper">
          <div className="icon-cards-row">
            <GlideComponent
              settings={{
                gap: 5,
                perView: 4,
                type: "carousel",
                breakpoints: {
                  320: { perView: 1 },
                  576: { perView: 2 },
                  1600: { perView: 4 },
                  1800: { perView: 4 },
                },
                hideNav: true,
              }}
            >
              <IconCard
                className="mb-4"
                icon="iconsminds-business-man-woman"
                title="Talabalar"
                value={data.length}
              />
              <IconCard
                className="mb-4"
                icon="iconsminds-male-female"
                title="30 yoshgacha bo'lganlar"
                value={data.belowThirties().length}
              />
              <IconCard
                className="mb-4 women"
                icon="iconsminds-business-woman"
                title="Xotin-qizlar"
                value={data.filter((one) => one.isFemale).length}
              />
              <IconCard
                className="mb-4 women"
                icon="iconsminds-female"
                title="30 yoshgacha bo'lgan Xotin-qizlar"
                value={
                  data.filter((one) => one.isFemale).belowThirties().length
                }
              />
            </GlideComponent>
          </div>
          <div>
            <Row>
              <Colxx xl="6" lg="6" md="12" className="mb-4">
                {/* <ProfileStatuses
                  title={"Talabalarning yashash holati bo'yicha ma'lumot "}
                  data={livingData}
                /> */}
                <CategoriesBarChart
                  title={"Talabalarning yashash holati bo'yicha ma'lumot "}
                  data={livingData}
                  child={
                    <ProfileStatusesSpan
                      title={"Talabalarning yashash holati bo'yicha ma'lumot "}
                      data={livingData}
                    />
                  }
                />
              </Colxx>
              <Colxx xl="6" lg="6" md="12" className="mb-4">
                <CategoriesBarChart
                  title={"Talabalarning oilaviy holati"}
                  data={marriageData}
                  child={
                    <ProfileStatusesSpan
                      title={"Talabalarning oilaviy holati"}
                      data={marriageData}
                    />
                  }
                />
              </Colxx>
              <Colxx xl="6" lg="6" md="12" className="mb-4">
                <CategoriesLineInterpolation
                  title={"Talabalarning ijtimoiy holati"}
                  data={socialData}
                  child={
                    <ProfileStatusesSpan
                      title={"Talabalarning ijtimoiy holati"}
                      data={socialData}
                      double
                    />
                  }
                />
              </Colxx>
              <Colxx xl="6" lg="6" md="12" className="mb-4">
                <CategoriesSteppedLine
                  title={"Iqtidorli talabalar"}
                  data={talentedData}
                  child={
                    <ProfileStatusesSpan
                      title={"Iqtidorli talabalar"}
                      data={talentedData}
                      double
                    />
                  }
                />
              </Colxx>
              <Colxx xl="6" lg="6" md="12" className="mb-4">
                <CategoriesLineDrawTime
                  title={"Talabalarning moddiy rag'batlantirilish holati"}
                  data={moneyAwardedData}
                  child={
                    <ProfileStatusesSpan
                      title={"Talabalarning moddiy rag'batlantirilish holati"}
                      data={moneyAwardedData}
                      double
                    />
                  }
                />
              </Colxx>
              <Colxx xl="6" lg="6" md="12" className="mb-4">
                <CategoriesRadar
                  title={
                    "Huquqbuzarlik va jinoyatchilik bo'yicha alohida nazoratda turuvchi talabalar"
                  }
                  data={specialControlData}
                  child={
                    <ProfileStatusesSpan
                      title={
                        "Huquqbuzarlik va jinoyatchilik bo'yicha alohida nazoratda turuvchi talabalar"
                      }
                      data={specialControlData}
                      double
                    />
                  }
                />
              </Colxx>
            </Row>
          </div>
          {user.role !== "Dekanat" && (
            <div className="text-zero top-right-button-container">
              <Button
                color="primary"
                className="top-right-button"
                onClick={handleDownload}
                disabled={downloadLoading}
              >
                {downloadLoading ? "Yuklanmoqda..." : "Excelda olish"}
              </Button>
            </div>
          )}
        </div>
      )}
    </AppLayout>
  );
};

export default MainDashboard;
