import { CHART_COLORS, CHART_COLORS_ALPHA } from "./Constants";

export const specialControlDataConstruct = (data) => {
  const titles = [
    "Notinch oiladagi yoshlar",
    "JIEMdan qaytganlar",
    "Probatsiya hisobida turuvchilar",
    "Ma'muriy huquqbuzarlik sodir etganlar",
    "Tazyiq va zo'ravonlikdan jabrlanganligi sababli “himoya orderi” berilgan xotin-qizlar",
    "O'z joniga qasd qilishga moyilligi bo'lganlar",
    "Boshqalar",
  ];
  let fromTroubledFamily = data.filter((one) => one.fromTroubledFamily);
  let fromJail = data.filter((one) => one.fromJail);
  let inProbabition = data.filter((one) => one.inProbabition);
  let committedOffence = data.filter((one) => one.committedOffence);
  let protectionOrder = data.filter((one) => one.protectionOrder);
  let suicideTendency = data.filter((one) => one.suicideTendency);
  let specialControlOthers = data.filter((one) => one.specialControlOthers);

  let resultData = [
    fromTroubledFamily.length,
    fromJail.length,
    inProbabition.length,
    committedOffence.length,
    protectionOrder.length,
    suicideTendency.length,
    specialControlOthers.length,
  ];

  let resultWomenData = [
    fromTroubledFamily.filter((one) => one.isFemale).length,
    fromJail.filter((one) => one.isFemale).length,
    inProbabition.filter((one) => one.isFemale).length,
    committedOffence.filter((one) => one.isFemale).length,
    protectionOrder.filter((one) => one.isFemale).length,
    suicideTendency.filter((one) => one.isFemale).length,
    specialControlOthers.filter((one) => one.isFemale).length,
  ];

  let simpleData = [];
  let labels = [];
  for (let i = 0; i < titles.length; i++) {
    labels.push(i + 1);
    simpleData.push({
      title: titles[i],
      total: resultData[i],
      women: resultWomenData[i],
    });
  }

  return {
    data: simpleData,
    chartData: {
      labels: labels,
      datasets: [
        {
          data: resultData,
          label: "Jami",
          borderColor: CHART_COLORS.blue,
          backgroundColor: CHART_COLORS_ALPHA.blue,
        },
        {
          data: resultWomenData,
          label: "Ayollar",
          borderColor: CHART_COLORS.red,
          backgroundColor: CHART_COLORS_ALPHA.red,
        },
      ],
      titles: titles,
    },
  };
};
