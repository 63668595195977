/* eslint-disable react/no-array-index-key */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, Progress } from "reactstrap";

const ProfileStatuses = ({ cardClass = "h-100", title, data }) => {
  const navigate = useNavigate();

  return (
    <Card className={""}>
      <CardBody>
        <CardTitle>{title}</CardTitle>
        {data.map((s, index) => {
          return (
            <div key={index} className="mb-4">
              <p
                className="mb-2"
                onDoubleClick={() =>
                  navigate(
                    `/app/students/filtered?title=${title}&subtitle=${s.title}&total=${s.total}`
                  )
                }
              >
                {s.title}
                <span className="float-right text-muted">
                  {s.women}/{s.total}
                </span>
              </p>
              <Progress
                className={
                  s.total === 0 ? `progress-bar-empty` : `progress-bar-men`
                }
                value={(s.women / s.total) * 100}
              />
            </div>
          );
        })}
      </CardBody>
    </Card>
  );
};
export default ProfileStatuses;
