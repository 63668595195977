import { CHART_COLORS, CHART_COLORS_ALPHA } from "./Constants";

export const moneyAwardedDataConstruct = (data) => {
  const titles = [
    "Xalqaro ta'lim dasturlari bo'yicha imtihon topshirganlar",
    "Xalqaro ta'lim dasturlari bo'yicha imtihon xarajatlari qoplab berilganlar",
    `"Yoshlar daftari" jamg'armasidan to'lov-shartnoma mablag'i to'lab berilgan`,
    "Mahalliy hokimliklar tomonidan to'lov-shartnoma mablag'i to'lab berilgan",
    "Oliy ta'lim muassasasi tomonidan moddiy rag'batlantirilganlar",
    "Oliy ta'lim muassasasi tomonidan shartnoma summasi to'lab berilganlar",
    "Homiylar va boshqa tashkilotlar tomonidan to'lov-shartnoma mablag'i to'lab berilgan",
    "Ijarada yashash uchun to'lov davlat tomonidan amalga oshirilganlar",
    "TTJ da yashash bo'yicha imtiyozga ega",
  ];
  let internationalEduProgsExam = data.filter(
    (one) => one.internationalEduProgsExam
  );
  let internationalEduProgsExamPaid = data.filter(
    (one) => one.internationalEduProgsExamPaid
  );
  let contractPaidYouthBook = data.filter((one) => one.contractPaidYouthBook);
  let contractPaidLocalAuth = data.filter((one) => one.contractPaidLocalAuth);
  let contractPaidUnivAward = data.filter((one) => one.contractPaidUnivAward);
  let contractPaidUnivPaid = data.filter((one) => one.contractPaidUnivPaid);
  let contractPaidLocalAuthSponsors = data.filter(
    (one) => one.contractPaidLocalAuthSponsors
  );
  let governmentRentPaid = data.filter((one) => one.governmentRentPaid);
  let rentPaid = data.filter((one) => one.rentPaid);

  let resultData = [
    internationalEduProgsExam.length,
    internationalEduProgsExamPaid.length,
    contractPaidYouthBook.length,
    contractPaidLocalAuth.length,
    contractPaidUnivAward.length,
    contractPaidUnivPaid.length,
    contractPaidLocalAuthSponsors.length,
    governmentRentPaid.length,
    rentPaid.length,
  ];

  let resultWomenData = [
    internationalEduProgsExam.filter((one) => one.isFemale).length,
    internationalEduProgsExamPaid.filter((one) => one.isFemale).length,
    contractPaidYouthBook.filter((one) => one.isFemale).length,
    contractPaidLocalAuth.filter((one) => one.isFemale).length,
    contractPaidUnivAward.filter((one) => one.isFemale).length,
    contractPaidUnivPaid.filter((one) => one.isFemale).length,
    contractPaidLocalAuthSponsors.filter((one) => one.isFemale).length,
    governmentRentPaid.filter((one) => one.isFemale).length,
    rentPaid.filter((one) => one.isFemale).length,
  ];

  let simpleData = [];
  let labels = [];
  for (let i = 0; i < titles.length; i++) {
    labels.push(i + 1);
    simpleData.push({
      title: titles[i],
      total: resultData[i],
      women: resultWomenData[i],
    });
  }

  return {
    data: simpleData,
    chartData: {
      labels: labels,
      datasets: [
        {
          data: resultData,
          label: "Jami",
          borderColor: CHART_COLORS.blue,
          backgroundColor: CHART_COLORS_ALPHA.blue,
          fill: true,
        },
        {
          data: resultWomenData,
          label: "Ayollar",
          borderColor: CHART_COLORS.red,
          backgroundColor: CHART_COLORS_ALPHA.red,
          fill: true,
        },
      ],
      titles: titles,
    },
  };
};
