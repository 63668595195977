import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Card, CardBody, Button } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";

import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";

const ViewStudent = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState({});

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();
  const { id } = useParams();
  const user = useUser();

  function clean(obj) {
    for (let propName in obj) {
      if (obj[propName] === "" || obj[propName] === false) {
        delete obj[propName];
      }
    }
    return obj;
  }

  useEffect(() => {
    setLoading(true);

    axios
      .get(`/api/student/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        let { data } = result.data;
        let newData = clean(data);
        setValues({
          ...newData,
          dob: new Date(data.dob),
          passportIssueDate: new Date(data.passportIssueDate),
        });
        setLoading(false);
        console.log(values);
      });
  }, [id]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        {!loading && (
          <Row>
            <Colxx xxs="12" md="12" xl="8" className="col-left">
              <Card className="mb-4">
                <CardBody>
                  <h5 className="card-title">Asosiy ma'lumotlar</h5>
                  <div className="mb-5" style={{ textAlign: "left" }}>
                    <p>
                      <strong>FIO: </strong>
                      {values.name} {values.surname} {values.middleName}
                    </p>
                    <p>
                      <strong>Jinsi: </strong>
                      {values.isFemale ? "Ayol" : "Erkak"}
                    </p>
                    <p>
                      <strong>Telefon raqami: </strong>
                      {values.phoneNumber}
                    </p>
                    <p>
                      <strong>Tugilgan joyi va sanasi: </strong>
                      {values.bithPlace},{" "}
                      {values.dob ? values.dob.toLocaleDateString("en-GB") : ""}
                    </p>
                    <p>
                      <strong>Manzili: </strong>
                      {values.livingRepublic}, {values.livingRegion},{" "}
                      {values.livingDistrict}, {values.livingAddress}
                    </p>
                    <p>
                      <strong>Passport raqami va berilgan sanasi: </strong>
                      {values.passportID},{" "}
                      {values.passportIssueDate
                        ? values.passportIssueDate.toLocaleDateString("en-GB")
                        : ""}
                    </p>
                    <p>
                      <strong>
                        Passport kim tomonidan berilgan va JShShIR:{" "}
                      </strong>
                      {values.passportIssuer}, {values.personIdentityNumber}
                    </p>
                    <p>
                      <strong>Ta'lim turi: </strong>
                      {values.educationType}
                    </p>
                    <p>
                      <strong>Ta'lim shakli: </strong>
                      {values.educationMode}
                    </p>
                    <p>
                      <strong>Ta'lim Bosqichi: </strong>
                      {values.educationYear}
                    </p>
                    <p>
                      <strong>To'lov shakli: </strong>
                      {values.isContract ? "Kontrakt" : "Grant"}
                    </p>
                    <p>
                      <strong>Mutaxassisligi: </strong>
                      {values.educationSpecialty}
                    </p>
                    <p>
                      <strong>Guruh raqami: </strong>
                      {values.groupNumber}
                    </p>
                    <p>
                      <strong>Yashash holati: </strong>
                      {values.livingStatus}
                    </p>
                    <p>
                      <strong>Oilaviy holati: </strong>
                      {values.marriageStatus}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Colxx>
            <Colxx xxs="12" md="12" xl="4" className="col-left">
              <Card className="mb-4">
                <CardBody>
                  <h5 className="card-title">Qo'shimcha ma'lumotlar</h5>
                  <div className="mb-5" style={{ textAlign: "left" }}>
                    <p>
                      <strong>Talabaning ijtimoiy holati:</strong>
                    </p>
                    <p>
                      {values.inYouthBook && "Yoshlar daftariga kiritilgan"}
                    </p>
                    <p>
                      {values.lowIncomeFamily && "Kam ta'minlangan oiladan"}
                    </p>
                    <p>
                      {values.ironNotebook && "Temir daftardagi oila farzandi"}
                    </p>
                    <p>
                      {values.motherInYouthBook &&
                        "Ayollar daftariga kiritilgan ona farzandi"}
                    </p>
                    <p>
                      {values.familyInHeavySocialStatus &&
                        "Og'ir ijtimoiy holatga tushib qolgan oila farzandi"}
                    </p>
                    <p>
                      {values.mercyBook && "Mehr daftariga kiritilgan talaba"}
                    </p>
                    <p>
                      {values.withPrivilage &&
                        "Imtiyoz asosida o'qishga qabul qilingan"}
                    </p>
                    <p>
                      {values.returnedMilitary &&
                        "Muddatli harbiy xizmatdan qaytgan: "}
                      {values.returnedMilitaryText}
                    </p>
                    <p>
                      {values.pensioner && "Nafaqa oluvchi, sababi: "}
                      {values.pensionerReason}
                    </p>
                    <p>
                      {values.isWorking && "Ishlaydigan talaba. Ish joyi: "}
                      {values.workingPlace}
                    </p>
                    <p>
                      {values.parentAbroad &&
                        "Otasi yoki onasi horijiy davlatda ishlaydi - "}
                      {values.parentAbroadDetails}
                    </p>
                    <p>{values.hasChild && "Farzandli talaba"}</p>
                    <p>
                      {values.noParentalRights &&
                        "Ota-onasi ota-onalik huquqidan mahrum etilgan"}
                    </p>
                    <p>
                      {values.fromOrphanage &&
                        "Mehribonlik uyi va Bolalar shaharchasida tarbiyalangan"}
                    </p>
                    <p>{values.weakSight && "Zaif ko'radi"}</p>
                    <p>
                      {values.movementInjured &&
                        "Tayanch harakati a'zolari shikastlangan"}
                    </p>
                    <p>{values.weakDeaf && "Zaif eshitadi"}</p>
                    <p>{values.blind && "Ko'zi ojiz"}</p>
                    <p>{values.deaf && "Kar"}</p>
                    <p>{values.speechDefect && "Nutqida nuqsoni bor"}</p>
                    <p>
                      {values.otherDisabled &&
                        "Boshqa turdagi nogironligi bor: "}
                      {values.otherDisabledText}
                    </p>
                    <p>
                      {values.disabled && `Nogironligi: ${values.disabled}`}
                    </p>
                    <p>{values.orphan && `Yetimliligi: ${values.orphan}`}</p>
                    <Separator />
                    <br />
                    <p>
                      <strong>Talabaning iqtidori:</strong>
                    </p>
                    <p>
                      {values.governmentAward &&
                        "Davlat mukofoti bilan taqdirlangan"}
                    </p>
                    <p>
                      {values.badgeAward &&
                        "Ko'krak nishonlari bilan taqdirlangan"}
                    </p>
                    <p>
                      {values.presidentScholarship &&
                        "Prezident stipendiyasi sohibi"}
                    </p>
                    <p>
                      {values.governmentScholarship &&
                        "Davlat stipendiyalari sohibi"}
                    </p>
                    <p>
                      {values.internationalScholarship &&
                        "Xalqaro stipendiyalar sohibi"}
                    </p>
                    <p>
                      {values.republicSport &&
                        "Respublika sport musobaqalari g'olibi"}
                    </p>
                    <p>
                      {values.internationalSport &&
                        "Xalqaro sport musobaqalari g'olibi"}
                    </p>
                    <p>
                      {values.republicScienceOlymp &&
                        "Respublika fan olimpiadalari g'olibi"}
                    </p>
                    <p>
                      {values.internationalScienceOlimp &&
                        "Xalqaro fan olimpiadalari g'olibi"}
                    </p>
                    <p>
                      {values.otherAwards && "Boshqa yutuq va imtiyozlarga ega"}
                    </p>
                    <Separator />
                    <br />
                    <p>
                      <strong>
                        Talabaning moddiy rag'batlantirilish holati:
                      </strong>
                    </p>
                    <p>
                      {values.rentPaid &&
                        "TTJ da yashash bo'yicha imtiyozga ega"}
                    </p>
                    <p>
                      {values.governmentRentPaid &&
                        "Ijarada yashash uchun to'lov davlat tomonidan amalga oshirilganlar"}
                    </p>
                    <p>
                      {values.internationalEduProgsExam &&
                        "Xalqaro ta'lim dasturlari bo'yicha imtihon topshirgan."}
                    </p>
                    <p>
                      {values.internationalEduProgsExamPaid &&
                        "Xalqaro ta'lim dasturlari bo'yicha imtihon harajatlari qoplab berilgan."}
                      {values.internationalEduProgsExamText}
                    </p>
                    <p>
                      {values.contractPaidYouthBook &&
                        `"Yoshlar daftari" jamg'armasidan to'lov shartnoma mablag'lari to'lab berilgan yoki moddiy rag'batlantirilagan`}
                    </p>
                    <p>
                      {values.contractPaidLocalAuth &&
                        `Mahalliy hokimliklar tomonidan to'lov shartnoma mablag'lari to'lab berilgan yoki moddiy rag'batlantirilagan`}
                    </p>
                    <p>
                      {values.contractPaidUnivAward &&
                        `OTM tomonidan moddiy rag'lantirilgan`}
                    </p>
                    <p>
                      {values.contractPaidUnivPaid &&
                        `OTM tomonidan shartnoma sumnmasi to'lab berilgan`}
                    </p>
                    <p>
                      {values.univMoneyAward &&
                        `Institutning talabalarni rag'batlantirish jamg'armasi mablag'lari hisobidan bir martalik rag'batlantirilgan. ${values.univMoneyAwardReason}: ${values.univMoneyAwardAmount} BS miqdori`}
                    </p>
                    <p>
                      {values.univScholarshipAward &&
                        `Institutning talabalarni rag'batlantirish jamg'armasi mablag'lari hisobidan talabalarga belgilangan stipendiyatlarga nisbatan foizlarda ustama belgilangan. ${values.univScholarshipAwardReason}: ${values.univScholarshipAwardAmount} ustama fozi`}
                    </p>
                    <p>
                      {values.contractPaidLocalAuthSponsors &&
                        "Homiylar va boshqa tashkilotlar tomonidan"}
                    </p>
                    <Separator />
                    <br />
                    <p>
                      <strong>
                        Huquqbuzarlik va jinoyatchilik bo'yicha alohida nazorat:
                      </strong>
                    </p>
                    <p>
                      {values.fromTroubledFamily && "Notinch oilada farzandi"}
                    </p>
                    <p>
                      {values.lightNatureWoman && "Yengil tabiatli xotin-qiz"}
                    </p>
                    <p>{values.fromJail && "JIEMdan qaytgan"}</p>
                    <p>
                      {values.inProbabition && "Probatsiya hisobida turadi"}
                    </p>
                    <p>
                      {values.committedOffence &&
                        "Ma'muriy huquqbuzarlik sodir etgan"}
                    </p>
                    <p>
                      {values.protectionOrder &&
                        "Tazyiq va zo'ravonlikdan jabrlanganligi sababli “himoya orderi” berilgan xotin-qiz"}
                    </p>
                    <p>
                      {values.suicideTendency &&
                        "O'z joniga qasd qilishga moyilligi bor"}
                    </p>
                    <p>
                      {values.specialControlOthers &&
                        `Boshqalar: ${values.specialControlOthersText}`}
                    </p>
                    <p>
                      {values.isGraduated &&
                        `O'qishni tamomlagan. Buyruq raqami: ${values.graduationOrder}. Ish joyi: ${values.graduationWork}`}
                    </p>
                    <Separator />
                    <p>
                      <strong>
                        <h3>Tutor: {values.tutorName}</h3>
                      </strong>
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div>
          <Button
            color="primary"
            className="top-right-button"
            onClick={() => navigate("/app/students")}
          >
            Orqaga
          </Button>
          {(user.role === "Admin" || user.role === "Tutor") && (
            <Button
              color="primary"
              className="top-right-button ml-1"
              onClick={() => navigate(`/app/students/edit/${id}`)}
            >
              Tahrirlash
            </Button>
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default ViewStudent;
