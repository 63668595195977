import axios from "axios";

const axiosClient = axios.create({
  // baseURL: "http://localhost:6001",
  baseURL: "https://yb-api.andmiedu.uz/",
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
);

export default axiosClient;
