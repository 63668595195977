/* eslint-disable react/no-array-index-key */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, Progress } from "reactstrap";

const ProfileStatusesSpan = ({ cardClass = "h-100", title, data, double }) => {
  const navigate = useNavigate();
  let dataIn = data.data ? data.data : data;
  return (
    <>
      {dataIn.map((s, index) => {
        return (
          <div key={index} className={`mb-4 ${double ? "col-6" : ""}`}>
            <p
              className="mb-2"
              onDoubleClick={() =>
                navigate(
                  `/app/students/filtered?title=${title}&subtitle=${s.title}&total=${s.total}`
                )
              }
            >
              {index + 1}. {s.title}
              <span className="float-right text-muted">
                {s.women}/{s.total}
              </span>
            </p>
            <Progress
              className={
                s.total === 0 ? `progress-bar-empty` : `progress-bar-men`
              }
              value={(s.women / s.total) * 100}
            />
          </div>
        );
      })}
    </>
  );
};
export default ProfileStatusesSpan;
