import { CHART_COLORS, CHART_COLORS_ALPHA } from "./Constants";

export const livingDataConstruct = (data) => {
  let result = [];
  let resultData = [];
  let resultWomenData = [];
  let labels = [];
  let options = [
    "O'z uyidan qatnab tahsil oladi",
    "Talabalar turar joyida yashaydi",
    "Ijarada yashaydi",
    "Qarindoshlarinikida yashaydi",
  ];
  let titles = [
    "O'z uyidan qatnab tahsil oladiganlar",
    "Talabalar turar joyida yashaydiganlar",
    "Ijarada yashaydiganlar",
    "Qarindoshlarinikida yashaydiganlar",
  ];
  for (let i = 0; i < options.length; i++) {
    labels.push(i + 1);
    const students = data.filter((one) => one.livingStatus === options[i]);
    const women = students.filter((one) => one.isFemale);
    resultData.push(students.length);
    resultWomenData.push(women.length);
    result.push({
      title: titles[i],
      total: students.length,
      women: women.length,
    });
  }
  return {
    data: result,
    chartData: {
      labels: labels,
      datasets: [
        {
          data: resultData,
          borderColor: CHART_COLORS.blue,
          backgroundColor: CHART_COLORS_ALPHA.blue,
          borderWidth: 2,
          borderRadius: 5,
          borderSkipped: false,
        },
        {
          data: resultWomenData,
          borderColor: CHART_COLORS.red,
          backgroundColor: CHART_COLORS_ALPHA.red,
          borderWidth: 2,
          borderRadius: 5,
          borderSkipped: false,
        },
      ],
      titles: titles,
    },
  };
};
