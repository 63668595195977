/* eslint-disable prefer-rest-params */
import React, { useEffect, useRef, useState } from "react";
import {
  Chart,
  RadarController,
  RadialLinearScale,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
} from "chart.js";

Chart.register(
  RadarController,
  RadialLinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title
);

const Radar = ({ data }) => {
  const chartContainer = useRef(null);
  const [, setChartInstance] = useState(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      //   const context = chartContainer.current.getContext("2d");
      const context = chartContainer.current;
      const newChartInstance = new Chart(context, {
        type: "radar",
        options: {
          responsive: true,
        },
        data,
      });
      setChartInstance(newChartInstance);
    }
  }, [chartContainer, data]);

  return <canvas ref={chartContainer} />;
};

export default Radar;
