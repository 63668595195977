import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  InputGroup,
  Input,
  InputGroupText,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import AppLayout from "layout/AppLayout";
import Select from "react-select";

import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";

const initialValues = {
  name: "",
  surname: "",
  isFemale: false,
  educationType: "",
  educationMode: "",
  educationYear: "",
  isContract: true,
  dob: null,
  livingStatus: "",
  marriageStatus: "",
  lowIncomeFamily: false,
  ironNotebook: false,
  withPrivilage: false,
  returnedMilitary: false,
  pensioner: false,
  isWorking: false,
  hasChild: false,
  noParentalRights: false,
  fromOrphanage: false,
  disabled: "",
  byCategories: false,
  weakSight: false,
  movementInjured: false,
  weakDeaf: false,
  blind: false,
  deaf: false,
  speechDefect: false,
  otherDisabled: false,
  orphan: "",
  governmentAward: false,
  badgeAward: false,
  presidentScholarship: false,
  governmentScholarship: false,
  internationalScholarship: false,
  republicSport: false,
  internationalSport: false,
  republicScienceOlymp: false,
  internationalScienceOlimp: false,
  otherAwards: false,
  internationalEduProgsExam: false,
  internationalEduProgsExamPaid: false,
  contractPaidYouthBook: false,
  contractPaidLocalAuth: false,
  contractPaidUnivAward: false,
  contractPaidUnivPaid: false,
  contractPaidLocalAuthSponsors: false,
  rentPaid: false,
  fromTroubledFamily: false,
  lightNatureWoman: false,
  fromJail: false,
  inProbabition: false,
  committedOffence: false,
  protectionOrder: false,
  suicideTendency: false,
  specialControlOthers: false,

  specialControlOthersText: "",

  bithPlace: "",
  educationSpecialty: "",
  livingRepublic: "",
  livingRegion: "",
  livingDistrict: "",
  livingAddress: "",
  phoneNumber: "",
  passportID: "",
  passportIssueDate: null,
  passportIssuer: "",
  personIdentityNumber: "",

  motherInYouthBook: false,
  mercyBook: false,
  familyInHeavySocialStatus: false,
  governmentRentPaid: false,

  inYouthBook: false,
  inYouthBookReason: "",
  inYouthBookFrom: null,
  inYouthBookDuration: "",

  returnedMilitaryText: "",
  pensionerReason: "",
  workingPlace: "",
  parentAbroad: false,
  parentAbroadDetails: "",
  otherDisabledText: "",
  accommodationPrivilage: false,
  internationalEduProgsExamText: "",
  univMoneyAward: false,
  univMoneyAwardReason: "",
  univMoneyAwardAmount: 1,
  univScholarshipAward: false,
  univScholarshipAwardReason: "",
  univScholarshipAwardAmount: 10,
  isGraduated: false,
  graduationOrder: "",
  graduationWork: "",
};

let educationSpecialties = [
  { value: "Iqtisodiyot", label: "Iqtisodiyot" },
  { value: "Menejment", label: "Menejment" },
  {
    value: "Buxgalteriya xisobi va audit",
    label: "Buxgalteriya xisobi va audit",
  },
  { value: "MTMICHJA", label: "MTMICHJA" },
  { value: "TMJ", label: "TMJ" },
  {
    value: "TJIChAB (tarmoqlar boyicha)",
    label: "TJIChAB (tarmoqlar boyicha)",
  },
  {
    value: "Mexatronika va Robototexnika",
    label: "Mexatronika va Robototexnika",
  },
  { value: "ATT", label: "ATT" },
  {
    value: "Intellektual Muhandislik Tizimlari",
    label: "Intellektual Muhandislik Tizimlari",
  },
  { value: "Muqobil energiya manbalari", label: "Muqobil energiya manbalari" },
  { value: "Elektr energetikasi", label: "Elektr energetikasi" },
  { value: "EEE", label: "EEE" },
  {
    value: "Energiya tejamkorligi va energoaudit",
    label: "Energiya tejamkorligi va energoaudit",
  },
  { value: "MSMSM", label: "MSMSM" },
  { value: "MYaMT", label: "MYaMT" },
  {
    value: "Avtomobilsozlik va traktorsozlik",
    label: "Avtomobilsozlik va traktorsozlik",
  },
  { value: "Biotibbiyot muhandisligi", label: "Biotibbiyot muhandisligi" },
  { value: "Energiya mashinasozligi", label: "Energiya mashinasozligi" },
  {
    value: "Hayotiy faoliyat xavfsizligi",
    label: "Hayotiy faoliyat xavfsizligi",
  },
  { value: "Xizmatlar sohasi", label: "Xizmatlar sohasi" },
  { value: "YeUTUE", label: "YeUTUE" },
  { value: "TTETL", label: "TTETL" },
  { value: "MMTX", label: "MMTX" },
  { value: "Kasb-ta'limi", label: "Kasb-ta'limi" },
  {
    value: "Transport vositalari muxandisligi",
    label: "Transport vositalari muxandisligi",
  },
  { value: "Transport logistikasi", label: "Transport logistikasi" },
  {
    value: "Yo'l harakatini tashkil etish",
    label: "Yo'l harakatini tashkil etish",
  },
  { value: "Avtomobil servisi", label: "Avtomobil servisi" },
  {
    value: "Yengil Sanoat Buyumlari KIT",
    label: "Yengil Sanoat Buyumlari KIT",
  },
  { value: "Metallar Texnologiyasi", label: "Metallar Texnologiyasi" },
  { value: "Yo'l Muhandisligi", label: "Yo'l Muhandisligi" },
  {
    value: "Moliya va Moliyaviy Texnologiyalar",
    label: "Moliya va Moliyaviy Texnologiyalar",
  },
  { value: "Bank Ishi va Audit", label: "Bank Ishi va Audit" },
  { value: "Tabiiy Tola DIT", label: "Tabiiy Tola DIT" },
  { value: "Sun'iy Intellekt", label: "Sun'iy Intellekt" },
  { value: "IATT", label: "IATT" },
  { value: "Elektr Ta'minoti", label: "Elektr Ta'minoti" },
  {
    value: "Intellektual Transport Tizimlari",
    label: "Intellektual Transport Tizimlari",
  },
  { value: "Elektr Mexanikasi", label: "Elektr Mexanikasi" },
  { value: "Metrologiya SSB", label: "Metrologiya SSB" },
  {
    value: "Tabiiy-biologik apparatlar TM",
    label: "Tabiiy-biologik apparatlar TM",
  },
  { value: "MMT", label: "MMT" },
  { value: "MashTexJ", label: "MashTexJ" },
  { value: "Payvandlash IChTJ", label: "Payvandlash IChTJ" },
  { value: "Mehnat Muhofazasi IChTJX", label: "Mehnat Muhofazasi IChTJX" },
  {
    value: "Elektrotexnik Majmualar va T",
    label: "Elektrotexnik Majmualar va T",
  },
  { value: "Mahsulotlar Xavfsizligi US", label: "Mahsulotlar Xavfsizligi US" },
  {
    value: "Avtomobil Transporti va YQTE",
    label: "Avtomobil Transporti va YQTE",
  },
  { value: "Avtomobillar va AX", label: "Avtomobillar va AX" },
  { value: "Harakat Xavfsizligi va UTE", label: "Harakat Xavfsizligi va UTE" },
  { value: "Xalqaro Ta'lim Dasturi", label: "Xalqaro Ta'lim Dasturi" },
  {
    value: "Neft va neft gazni qayta ishlash texnologiyasi",
    label: "Neft va neft gazni qayta ishlash texnologiyasi",
  },
  {
    value: "Dizayn (avtomobilsozlik dizayni)",
    label: "Dizayn (avtomobilsozlik dizayni)",
  },
];

const AddStudent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState(initialValues);
  const [tutors, setTutors] = useState([]);
  const [selectTutors, setSelectTutors] = useState([]);
  const [isTutorChanged, setIsTutorChanged] = useState(false);

  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();
  const [errorMessage, setErrorMessage] = useState("");
  const user = useUser();
  const { id } = useParams();

  const handleChange = (e) => {
    setErrorMessage("");
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (name === "isGraduated") {
      setValues({
        ...values,
        [name]: checked,
        isActive: !checked,
      });
    } else {
      setValues({
        ...values,
        [name]: checked,
      });
    }
  };

  const handleSelectChange = (e, name) => {
    // console.log(name, e);
    let tempValues = {
      ...values,
      [name]:
        name === "dob"
          ? e
          : name === "passportIssueDate"
          ? e
          : name === "inYouthBookFrom"
          ? e
          : e.value,
    };
    if (name === "customTutor") {
      setIsTutorChanged(true);
      tempValues.tutor = e.value;
      tempValues.tutorName = e.label;
    }
    setValues(tempValues);
  };

  function clean(obj) {
    for (let propName in obj) {
      if (obj[propName] === "") {
        delete obj[propName];
      }
    }
    return obj;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(user);
    if (values.name === "" || values.surname === "") {
      setErrorMessage("Kerakli ma'lumotlarni kiriting");
    } else {
      let data = clean(values);
      if (isNew) {
        data.tutor = user.username;
        data.tutorName = user.name;
      }
      axios
        .post(`${isNew ? "/api/newstudent" : `/api/student/${id}`}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          if (result.data.success) {
            navigate("/app/students");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 409) {
            setErrorMessage("Bu talaba avval qo'shilgan");
          }
        });
    }
  };

  const handleDeleteStudent = () => {
    axios
      .delete(`/api/student/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.data.success) {
          navigate("/app/students");
        }
      });
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/api/student/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          let { data } = result.data;
          setValues({
            ...data,
            dob: new Date(data.dob),
            passportIssueDate: new Date(data.passportIssueDate),
            inYouthBookFrom: data.inYouthBookFrom
              ? new Date(data.inYouthBookFrom)
              : null,
          });
          axios
            .get(`api/tutors`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              let { data: tutorData } = res.data;
              setTutors(tutorData);
              let tempOptions = [];
              tutorData.forEach((one) => {
                tempOptions.push({
                  label: `${one.firstName} ${one.lastName}`,
                  value: one.username,
                });
              });
              setSelectTutors(tempOptions);
            });
          setLoading(false);
        });
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{isNew ? "Yangi Talaba" : "Tahrirlash"}</h1>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <CardTitle>Asosiy Ma'lumotlar</CardTitle>
                  <Form>
                    <Row>
                      <Col md="4">
                        <div sm="3" className="form-group has-float-label">
                          <Input
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                          />
                          <span>Ismi</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div sm="3" className="form-group has-float-label">
                          <Input
                            type="text"
                            name="surname"
                            value={values.surname}
                            onChange={handleChange}
                          />
                          <span>Familiyasi</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div sm="3" className="form-group has-float-label">
                          <Input
                            type="text"
                            name="middleName"
                            value={values.middleName}
                            onChange={handleChange}
                          />
                          <span>Otasining ismi</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group has-float-label">
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="isFemale"
                            value={{
                              label: values.isFemale ? "Ayol" : "Erkak",
                              value: values.isFemale,
                            }}
                            onChange={(e) => handleSelectChange(e, "isFemale")}
                            options={[
                              { label: "Erkak", value: false },
                              { label: "Ayol", value: true },
                            ]}
                          />
                          <span>Jinsi</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div sm="3" className="form-group has-float-label">
                          <Input
                            type="text"
                            name="phoneNumber"
                            value={values.phoneNumber}
                            onChange={handleChange}
                          />
                          <span>Telefon raqami</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div sm="3" className="form-group has-float-label">
                          <Input
                            type="text"
                            name="bithPlace"
                            value={values.bithPlace}
                            onChange={handleChange}
                          />
                          <span>Tug'ilgan joyi</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group has-float-label">
                          <DatePicker
                            name="dob"
                            selected={values.dob}
                            onChange={(e) => handleSelectChange(e, "dob")}
                          />
                          <span>Tug'ilgan sanasi</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div sm="3" className="form-group has-float-label">
                          <Input
                            type="text"
                            name="livingRepublic"
                            value={values.livingRepublic}
                            onChange={handleChange}
                          />
                          <span>Respublika</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div sm="3" className="form-group has-float-label">
                          <Input
                            type="text"
                            name="livingRegion"
                            value={values.livingRegion}
                            onChange={handleChange}
                          />
                          <span>Viloyat</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div sm="3" className="form-group has-float-label">
                          <Input
                            type="text"
                            name="livingDistrict"
                            value={values.livingDistrict}
                            onChange={handleChange}
                          />
                          <span>Tuman</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div sm="3" className="form-group has-float-label">
                          <Input
                            type="text"
                            name="livingAddress"
                            value={values.livingAddress}
                            onChange={handleChange}
                          />
                          <span>Uy manzili (MFY, ko'cha, uy raqami)</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div sm="3" className="form-group has-float-label">
                          <Input
                            type="text"
                            name="passportID"
                            value={values.passportID}
                            onChange={handleChange}
                          />
                          <span>Passport raqami</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group has-float-label">
                          <DatePicker
                            name="passportIssueDate"
                            selected={values.passportIssueDate}
                            onChange={(e) =>
                              handleSelectChange(e, "passportIssueDate")
                            }
                          />
                          <span>Berilgan sanasi</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div sm="3" className="form-group has-float-label">
                          <Input
                            type="text"
                            name="passportIssuer"
                            value={values.passportIssuer}
                            onChange={handleChange}
                          />
                          <span>Kim tomonidan berilgan</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div sm="3" className="form-group has-float-label">
                          <Input
                            type="number"
                            name="personIdentityNumber"
                            value={values.personIdentityNumber}
                            onChange={handleChange}
                          />
                          <span>JShShIR</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group has-float-label">
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="educationType"
                            value={{
                              label: values.educationType,
                              value: values.educationType,
                            }}
                            onChange={(e) =>
                              handleSelectChange(e, "educationType")
                            }
                            options={[
                              { label: "Bakalavr", value: "Bakalavr" },
                              {
                                label: "Magistratura",
                                value: "Magistratura",
                              },
                            ]}
                          />
                          <span>Ta'lim turi</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="form-group has-float-label">
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="educationMode"
                            value={{
                              label: values.educationMode,
                              value: values.educationMode,
                            }}
                            onChange={(e) =>
                              handleSelectChange(e, "educationMode")
                            }
                            options={[
                              { label: "Kunduzgi", value: "Kunduzgi" },
                              { label: "Sirtqi", value: "Sirtqi" },
                            ]}
                          />
                          <span>Ta'lim Shakli</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group has-float-label">
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="educationYear"
                            value={{
                              label: values.educationYear,
                              value: values.educationYear,
                            }}
                            onChange={(e) =>
                              handleSelectChange(e, "educationYear")
                            }
                            options={
                              values.educationType === "Bakalavr"
                                ? [
                                    { label: "1-bosqich", value: "1-bosqich" },
                                    { label: "2-bosqich", value: "2-bosqich" },
                                    { label: "3-bosqich", value: "3-bosqich" },
                                    { label: "4-bosqich", value: "4-bosqich" },
                                    { label: "5-bosqich", value: "5-bosqich" },
                                    { label: "6-bosqich", value: "6-bosqich" },
                                  ]
                                : [
                                    { label: "M-1", value: "M-1" },
                                    { label: "M-2", value: "M-2" },
                                    { label: "M-3", value: "M-3" },
                                  ]
                            }
                          />
                          <span>Ta'lim Bosqichi</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group has-float-label">
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="isContract"
                            value={{
                              label: values.isContract ? "Kontrakt" : "Grant",
                              value: values.isContract,
                            }}
                            onChange={(e) =>
                              handleSelectChange(e, "isContract")
                            }
                            options={[
                              { label: "Kontrakt", value: true },
                              { label: "Grant", value: false },
                            ]}
                          />
                          <span>To'lov Shakli</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="form-group has-float-label">
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="educationSpecialty"
                            value={{
                              label: values.educationSpecialty,
                              value: values.educationSpecialty,
                            }}
                            onChange={(e) =>
                              handleSelectChange(e, "educationSpecialty")
                            }
                            options={educationSpecialties}
                          />
                          <span>Mutaxassisligi</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div sm="3" className="form-group has-float-label">
                          <Input
                            type="text"
                            name="groupNumber"
                            value={values.groupNumber}
                            onChange={handleChange}
                          />
                          <span>Guruh raqami</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group has-float-label">
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="livingStatus"
                            value={{
                              label: values.livingStatus,
                              value: values.livingStatus,
                            }}
                            onChange={(e) =>
                              handleSelectChange(e, "livingStatus")
                            }
                            options={[
                              {
                                label: "O'z uyidan qatnab tahsil oladi",
                                value: "O'z uyidan qatnab tahsil oladi",
                              },
                              {
                                label: "Talabalar turar joyida yashaydi",
                                value: "Talabalar turar joyida yashaydi",
                              },
                              {
                                label: "Ijarada yashaydi",
                                value: "Ijarada yashaydi",
                              },
                              {
                                label: "Qarindoshlarinikida yashaydi",
                                value: "Qarindoshlarinikida yashaydi",
                              },
                            ]}
                          />
                          <span>Yashash holati</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group has-float-label">
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="marriageStatus"
                            value={{
                              label: values.marriageStatus,
                              value: values.marriageStatus,
                            }}
                            onChange={(e) =>
                              handleSelectChange(e, "marriageStatus")
                            }
                            options={[
                              {
                                label: "Turmush qurmagan",
                                value: "Turmush qurmagan",
                              },
                              {
                                label: "Turmush qurgan",
                                value: "Turmush qurgan",
                              },
                              { label: "Ajrashgan", value: "Ajrashgan" },
                            ]}
                          />
                          <span>Oilaviy holati</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group has-float-label">
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="marriageStatus"
                            value={
                              isTutorChanged
                                ? {
                                    label: values.tutorName,
                                    value: values.tutor,
                                  }
                                : {
                                    label: user.name,
                                    value: user.username,
                                  }
                            }
                            onChange={(e) =>
                              handleSelectChange(e, "customTutor")
                            }
                            options={selectTutors}
                          />
                          <span>Tutor</span>
                        </div>
                      </Col>
                      {/* <Col>
                        <InputGroup>
                          <InputGroupText>Logo</InputGroupText>
                          <Input
                            type="file"
                            name="logo"
                            onChange={handleImage}
                            accept="image/*"
                            disabled={values.loading}
                          />
                          {preview && (
                            <>
                              <img
                                alt={"logo"}
                                src={preview}
                                className="img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall"
                              />
                              <Button
                                outline
                                color="danger"
                                onClick={handleImageRemove}
                                style={{ margin: "auto 0" }}
                              >
                                x
                              </Button>
                            </>
                          )}
                        </InputGroup>
                      </Col> */}
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              <Card className="mb-4 text-start">
                <CardBody>
                  <Row>
                    <Col md="4">
                      <Card className="mb-4">
                        <CardBody>
                          <CardTitle>Talabaning ijtimoiy holati</CardTitle>
                          <Form>
                            <Row>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="inYouthBook"
                                    checked={values.inYouthBook}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Yoshlar daftariga kiritilgan"}
                                </FormGroup>
                              </Col>

                              <Col
                                md="12"
                                className="additional-input"
                                hidden={!values.inYouthBook}
                              >
                                <div
                                  sm="3"
                                  className="form-group has-float-label"
                                >
                                  <DatePicker
                                    name="inYouthBookFrom"
                                    selected={
                                      values.inYouthBookFrom
                                        ? values.inYouthBookFrom
                                        : null
                                    }
                                    onChange={(e) =>
                                      handleSelectChange(e, "inYouthBookFrom")
                                    }
                                  />
                                  <span>Kiritilgan sanasi</span>
                                </div>
                              </Col>
                              <Col
                                md="12"
                                className="additional-input"
                                hidden={!values.inYouthBook}
                              >
                                <div
                                  sm="3"
                                  className="form-group has-float-label"
                                >
                                  <Input
                                    type="text"
                                    name="inYouthBookReason"
                                    value={values.inYouthBookReason}
                                    onChange={handleChange}
                                  />
                                  <span>Kiritilish sababi</span>
                                </div>
                              </Col>
                              <Col
                                md="12"
                                className="additional-input"
                                hidden={!values.inYouthBook}
                              >
                                <div
                                  sm="3"
                                  className="form-group has-float-label"
                                >
                                  <Input
                                    type="text"
                                    name="inYouthBookDuration"
                                    value={values.inYouthBookDuration}
                                    onChange={handleChange}
                                  />
                                  <span>Chiqarish muddati</span>
                                </div>
                              </Col>

                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="lowIncomeFamily"
                                    checked={values.lowIncomeFamily}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Kam ta'minlangan oiladan"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="ironNotebook"
                                    checked={values.ironNotebook}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Temir daftardagi oila farzandi"}
                                </FormGroup>
                              </Col>

                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="motherInYouthBook"
                                    checked={values.motherInYouthBook}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Ayollar daftariga kiritilgan ona farzandi"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="familyInHeavySocialStatus"
                                    checked={values.familyInHeavySocialStatus}
                                    onChange={handleCheckboxChange}
                                  />
                                  {
                                    "Og'ir ijtimoiy holatga tushib qolgan oila farzandi"
                                  }
                                </FormGroup>
                              </Col>

                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="mercyBook"
                                    checked={values.mercyBook}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Mehr daftariga kiritilgan talaba"}
                                </FormGroup>
                              </Col>

                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="withPrivilage"
                                    checked={values.withPrivilage}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Imtiyoz asosida o'qishga qabul qilingan"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="returnedMilitary"
                                    checked={values.returnedMilitary}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Muddatli harbiy xizmatdan qaytgan"}
                                </FormGroup>
                              </Col>
                              <Col
                                md="12"
                                className="additional-input"
                                hidden={!values.returnedMilitary}
                              >
                                <div className="form-group has-float-label">
                                  <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="returnedMilitaryText"
                                    value={{
                                      label: values.returnedMilitaryText,
                                      value: values.returnedMilitaryText,
                                    }}
                                    onChange={(e) =>
                                      handleSelectChange(
                                        e,
                                        "returnedMilitaryText"
                                      )
                                    }
                                    options={[
                                      {
                                        label:
                                          "Bir yillik harbiy xizmat - tavsiyanomaga ega",
                                        value:
                                          "Bir yillik harbiy xizmat - tavsiyanomaga ega",
                                      },
                                      {
                                        label:
                                          "Bir yillik harbiy xizmat - tavsiyanomaga ega emas",
                                        value:
                                          "Bir yillik harbiy xizmat - tavsiyanomaga ega emas",
                                      },
                                      {
                                        label: "Bir oylik harbiy xizmat",
                                        value: "Bir oylik harbiy xizmat",
                                      },
                                    ]}
                                  />
                                </div>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="pensioner"
                                    checked={values.pensioner}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Nafaqa oluvchi"}
                                </FormGroup>
                              </Col>
                              <Col
                                md="12"
                                className="additional-input"
                                hidden={!values.pensioner}
                              >
                                <div
                                  sm="3"
                                  className="form-group has-float-label"
                                >
                                  <Input
                                    type="text"
                                    name="pensionerReason"
                                    value={values.pensionerReason}
                                    onChange={handleChange}
                                  />
                                  <span>Nafaqa berilishining sababi</span>
                                </div>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="isWorking"
                                    checked={values.isWorking}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Ishlaydigan talaba"}
                                </FormGroup>
                              </Col>
                              <Col
                                md="12"
                                className="additional-input"
                                hidden={!values.isWorking}
                              >
                                <div
                                  sm="3"
                                  className="form-group has-float-label"
                                >
                                  <Input
                                    type="text"
                                    name="workingPlace"
                                    value={values.workingPlace}
                                    onChange={handleChange}
                                  />
                                  <span>Ish joyi</span>
                                </div>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="parentAbroad"
                                    checked={values.parentAbroad}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Otasi yoki onasi horijiy davlatda ishlaydi"}
                                </FormGroup>
                              </Col>
                              <Col
                                md="12"
                                className="additional-input"
                                hidden={!values.parentAbroad}
                              >
                                <div
                                  sm="3"
                                  className="form-group has-float-label"
                                >
                                  <Input
                                    type="text"
                                    name="parentAbroadDetails"
                                    value={values.parentAbroadDetails}
                                    onChange={handleChange}
                                  />
                                  <span>Kasbi va qaysi davlatda ishlaydi</span>
                                </div>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="hasChild"
                                    checked={values.hasChild}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Farzandli talaba"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="noParentalRights"
                                    checked={values.noParentalRights}
                                    onChange={handleCheckboxChange}
                                  />
                                  {
                                    "Ota-onasi ota-onalik huquqidan mahrum etilgan"
                                  }
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="fromOrphanage"
                                    checked={values.fromOrphanage}
                                    onChange={handleCheckboxChange}
                                  />
                                  {
                                    "Mehribonlik uyi va Bolalar shaharchasida tarbiyalangan"
                                  }
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <br />
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="weakSight"
                                    checked={values.weakSight}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Zaif ko'radi"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="movementInjured"
                                    checked={values.movementInjured}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Tayanch harakati a'zolari shikastlangan"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="weakDeaf"
                                    checked={values.weakDeaf}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Zaif eshitadi"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="blind"
                                    checked={values.blind}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Ko'zi ojiz"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="deaf"
                                    checked={values.deaf}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Kar"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="speechDefect"
                                    checked={values.speechDefect}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Nutqida nuqsoni bor"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="otherDisabled"
                                    checked={values.otherDisabled}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Boshqa turdagi nogironligi bor"}
                                </FormGroup>
                              </Col>
                              <Col
                                md="12"
                                className="additional-input"
                                hidden={!values.otherDisabled}
                              >
                                <div
                                  sm="3"
                                  className="form-group has-float-label"
                                >
                                  <Input
                                    type="text"
                                    name="otherDisabledText"
                                    value={values.otherDisabledText}
                                    onChange={handleChange}
                                  />
                                  <span>Turi</span>
                                </div>
                              </Col>
                              <Col md="12">
                                <br />
                              </Col>
                              <Col md="12">
                                <div className="form-group has-float-label">
                                  <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="disabled"
                                    value={{
                                      label: values.disabled,
                                      value: values.disabled,
                                    }}
                                    onChange={(e) =>
                                      handleSelectChange(e, "disabled")
                                    }
                                    options={[
                                      {
                                        label: "Yo'q",
                                        value: "",
                                      },
                                      {
                                        label: "1-guruh",
                                        value: "1-guruh",
                                      },
                                      {
                                        label: "2-guruh",
                                        value: "2-guruh",
                                      },
                                      { label: "3-guruh", value: "3-guruh" },
                                    ]}
                                  />
                                  <span>Nogironligi</span>
                                </div>
                              </Col>
                              <Col md="12">
                                <div className="form-group has-float-label">
                                  <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="orphan"
                                    value={{
                                      label: values.orphan,
                                      value: values.orphan,
                                    }}
                                    onChange={(e) =>
                                      handleSelectChange(e, "orphan")
                                    }
                                    options={[
                                      {
                                        label: "Yo'q",
                                        value: "",
                                      },
                                      {
                                        label: "Chin yetim",
                                        value: "Chin yetim",
                                      },
                                      {
                                        label: "Yarim yetim",
                                        value: "Yarim yetim",
                                      },
                                      {
                                        label: "Ijtimoiy yetim",
                                        value: "Ijtimoiy yetim",
                                      },
                                    ]}
                                  />
                                  <span>Yetimligi</span>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="4">
                      <Card className="mb-4">
                        <CardBody>
                          <CardTitle>Talabaning iqtidori</CardTitle>
                          <Form>
                            <Row>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="governmentAward"
                                    checked={values.governmentAward}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Davlat mukofoti bilan taqdirlangan"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="badgeAward"
                                    checked={values.badgeAward}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Ko'krak nishonlari bilan taqdirlangan"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="presidentScholarship"
                                    checked={values.presidentScholarship}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Prezident stipendiyasi sohibi"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="governmentScholarship"
                                    checked={values.governmentScholarship}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Davlat stipendiyalari sohibi"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="internationalScholarship"
                                    checked={values.internationalScholarship}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Xalqaro stipendiyalar sohibi"}
                                </FormGroup>
                              </Col>

                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="republicSport"
                                    checked={values.republicSport}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Respublika sport musobaqalari g'olibi"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="internationalSport"
                                    checked={values.internationalSport}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Xalqaro sport musobaqalari g'olibi"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="republicScienceOlymp"
                                    checked={values.republicScienceOlymp}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Respublika fan olimpiadalari g'olibi"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="internationalScienceOlimp"
                                    checked={values.internationalScienceOlimp}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Xalqaro fan olimpiadalari g'olibi"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="otherAwards"
                                    checked={values.otherAwards}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Boshqa yutuq va imtiyozlarga ega"}
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </CardBody>
                      </Card>
                      <Card className="mb-4">
                        <CardBody>
                          <CardTitle>
                            Talabaning moddiy rag'batlantirilish holati
                          </CardTitle>
                          <Form>
                            <Row>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="rentPaid"
                                    checked={values.rentPaid}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"TTJ da yashash bo'yicha imtiyozga ega"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="governmentRentPaid"
                                    checked={values.governmentRentPaid}
                                    onChange={handleCheckboxChange}
                                  />
                                  {
                                    "Ijarada yashash uchun to'lov davlat tomonidan amalga oshirilganlar"
                                  }
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <br />
                              </Col>
                              <Col md="12">
                                <strong>
                                  Xalqaro ta'lim dasturlari bo'yicha imtihon
                                  topshirgan va xarajatlari qoplab berilgan
                                  bo'lsa,
                                </strong>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="internationalEduProgsExam"
                                    checked={values.internationalEduProgsExam}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Imtihon topshirgan"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="internationalEduProgsExamPaid"
                                    checked={
                                      values.internationalEduProgsExamPaid
                                    }
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Imtihon harajatlari qoplab berilgan"}
                                </FormGroup>
                              </Col>
                              <Col
                                md="12"
                                className="additional-input"
                                hidden={
                                  !(
                                    values.internationalEduProgsExamPaid ||
                                    values.internationalEduProgsExam
                                  )
                                }
                              >
                                <div
                                  sm="3"
                                  className="form-group has-float-label"
                                >
                                  <Input
                                    type="text"
                                    name="internationalEduProgsExamText"
                                    value={values.internationalEduProgsExamText}
                                    onChange={handleChange}
                                  />
                                  <span>
                                    Qaysi chet tili bo'yicha qancha ball oldi?
                                  </span>
                                </div>
                              </Col>
                              <Col md="12">
                                <br />
                              </Col>
                              <Col md="12">
                                <strong>
                                  To'lov shartnoma mablag'lari to'lab berilgan
                                  (kim tomonidan) yoki moddiy
                                  rag'batlantirilagan
                                </strong>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="contractPaidYouthBook"
                                    checked={values.contractPaidYouthBook}
                                    onChange={handleCheckboxChange}
                                  />
                                  {`"Yoshlar daftari" jamg'armasidan`}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="contractPaidLocalAuth"
                                    checked={values.contractPaidLocalAuth}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Mahalliy hokimliklar tomonidan"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="contractPaidUnivAward"
                                    checked={values.contractPaidUnivAward}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"OTM tomonidan moddiy rag'lantirilgan"}
                                </FormGroup>
                              </Col>

                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="contractPaidUnivPaid"
                                    checked={values.contractPaidUnivPaid}
                                    onChange={handleCheckboxChange}
                                  />
                                  {
                                    "OTM tomonidan shartnoma sumnmasi to'lab berilgan"
                                  }
                                </FormGroup>
                              </Col>

                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="univMoneyAward"
                                    checked={values.univMoneyAward}
                                    onChange={handleCheckboxChange}
                                  />
                                  {
                                    "Institutning talabalarni rag'batlantirish jamg'armasi mablag'lari hisobidan bir martalik rag'batlantirilgan"
                                  }
                                </FormGroup>
                              </Col>
                              <Col
                                md="12"
                                className="additional-input"
                                hidden={!values.univMoneyAward}
                              >
                                <Row>
                                  <Col md="8">
                                    <div className="form-group has-float-label">
                                      <Input
                                        type="text"
                                        name="univMoneyAwardReason"
                                        value={values.univMoneyAwardReason}
                                        onChange={handleChange}
                                      />
                                      <span>Sababi</span>
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="form-group has-float-label">
                                      <Input
                                        type="number"
                                        name="univMoneyAwardAmount"
                                        value={values.univMoneyAwardAmount}
                                        onChange={handleChange}
                                      />
                                      <span>BS miqdori</span>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="univScholarshipAward"
                                    checked={values.univScholarshipAward}
                                    onChange={handleCheckboxChange}
                                  />
                                  {
                                    "Institutning talabalarni rag'batlantirish jamg'armasi mablag'lari hisobidan talabalarga belgilangan stipendiyatlarga nisbatan foizlarda ustama belgilangan"
                                  }
                                </FormGroup>
                              </Col>
                              <Col
                                md="12"
                                className="additional-input"
                                hidden={!values.univScholarshipAward}
                              >
                                <Row>
                                  <Col md="8">
                                    <div className="form-group has-float-label">
                                      <Input
                                        type="text"
                                        name="univMoneyAwardReason"
                                        value={
                                          values.univScholarshipAwardReason
                                        }
                                        onChange={handleChange}
                                      />
                                      <span>Sababi</span>
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="form-group has-float-label">
                                      <Input
                                        type="number"
                                        name="univScholarshipAwardAmount"
                                        value={
                                          values.univScholarshipAwardAmount
                                        }
                                        onChange={handleChange}
                                      />
                                      <span>Ustama foiz</span>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="contractPaidLocalAuthSponsors"
                                    checked={
                                      values.contractPaidLocalAuthSponsors
                                    }
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Homiylar va boshqa tashkilotlar tomonidan"}
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="4">
                      <Card className="mb-4">
                        <CardBody>
                          <CardTitle>
                            Huquqbuzarlik va jinoyatchilik bo'yicha alohida
                            nazorat
                          </CardTitle>
                          <Form>
                            <Row>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="fromTroubledFamily"
                                    checked={values.fromTroubledFamily}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Notinch oilada farzandi"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="fromJail"
                                    checked={values.fromJail}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"JIEMdan qaytgan"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="inProbabition"
                                    checked={values.inProbabition}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Probatsiya hisobida turadi"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="committedOffence"
                                    checked={values.committedOffence}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Ma'muriy huquqbuzarlik sodir etgan"}
                                </FormGroup>
                              </Col>

                              <Col md="12">
                                <FormGroup check hidden={!values.isFemale}>
                                  <Input
                                    type="checkbox"
                                    name="protectionOrder"
                                    checked={values.protectionOrder}
                                    onChange={handleCheckboxChange}
                                  />
                                  {
                                    "Tazyiq va zo'ravonlikdan jabrlanganligi sababli “himoya orderi” berilgan xotin-qiz"
                                  }
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="suicideTendency"
                                    checked={values.suicideTendency}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"O'z joniga qasd qilishga moyilligi bor"}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="specialControlOthers"
                                    checked={values.specialControlOthers}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"Boshqalar"}
                                </FormGroup>
                              </Col>
                              <Col
                                md="12"
                                className="additional-input"
                                hidden={!values.specialControlOthers}
                              >
                                <div
                                  sm="3"
                                  className="form-group has-float-label"
                                >
                                  <Input
                                    type="text"
                                    name="specialControlOthersText"
                                    value={values.specialControlOthersText}
                                    onChange={handleChange}
                                  />
                                  <span>Huquqbuzarlik holatini kiriting</span>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="4">
                      <Card className="mb-4">
                        <CardBody>
                          <CardTitle>Qo'shimcha ma'lumotlar</CardTitle>
                          <Form>
                            <Row>
                              <Col md="12">
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    name="isGraduated"
                                    checked={values.isGraduated}
                                    onChange={handleCheckboxChange}
                                  />
                                  {"O'qishni tamomladi"}
                                </FormGroup>
                              </Col>
                              <Col
                                md="12"
                                className="additional-input"
                                hidden={!values.isGraduated}
                              >
                                <div
                                  sm="3"
                                  className="form-group has-float-label"
                                >
                                  <Input
                                    type="text"
                                    name="graduationOrder"
                                    value={values.graduationOrder}
                                    onChange={handleChange}
                                  />
                                  <span>Buyruq raqamini kiriting</span>
                                </div>
                                <div
                                  sm="3"
                                  className="form-group has-float-label"
                                >
                                  <Input
                                    type="text"
                                    name="graduationWork"
                                    value={values.graduationWork}
                                    onChange={handleChange}
                                  />
                                  <span>Ish joyini kiriting</span>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div>
          <Button
            color="primary"
            className="top-right-button"
            onClick={() =>
              navigate(isNew ? "/app/students" : `/app/students/${id}`)
            }
          >
            Orqaga
          </Button>
          {!isNew && (
            <Button
              color="primary"
              className="top-right-button ml-1"
              onClick={handleDeleteStudent}
            >
              O'chirish
            </Button>
          )}
          <Button
            color="primary"
            className="top-right-button ml-1"
            onClick={handleSubmit}
          >
            {isNew ? "Qo'shish" : "Yangilash"}
          </Button>
        </div>
        <p className="mt-1">{errorMessage}</p>
      </div>
    </AppLayout>
  );
};

export default AddStudent;
