import React, { useEffect } from "react";
import { Row, Card, CardTitle } from "reactstrap";
import { NavLink } from "react-router-dom";
import { Colxx } from "components/common/CustomBootstrap";

const Error = () => {
  return (
    <>
      <div className="fixed-background" />
      <main>
        <div className="container">
          <Row className="h-100">
            <Colxx xxs="12" md="10" className="mx-auto my-auto">
              <Card className="auth-card">
                <div className="position-relative image-side ">
                  <p className="text-white h2">Yoshlar Balansi</p>
                  <p className="white mb-0">AndMI</p>
                </div>
                <div className="form-side">
                  <CardTitle className="mb-4">Error</CardTitle>
                  <p className="mb-0 text-muted text-small mb-0">Err Code</p>
                  <p className="display-1 font-weight-bold mb-5">404</p>
                  <NavLink to="/" className="btn btn-primary btn-shadow btn-lg">
                    Orqaga qaytish
                  </NavLink>
                </div>
              </Card>
            </Colxx>
          </Row>
        </div>
      </main>
    </>
  );
};

export default Error;
