import React, { useEffect, useState, useMemo } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";

const DashboardStudentsList = (props) => {
  const { incomingData } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tutor, setTutor] = useState("");
  const [word, setWord] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [count, setCount] = useState(null);
  const [token, setToken] = useToken();
  const [title, setTitle] = useState("");
  const [total, setTotal] = useState(null);
  const [subtitle, setSubtitle] = useState("");
  const [sQuery, setSQuery] = useState("");
  const user = useUser();

  // eslint-disable-next-line no-extend-native
  Array.prototype.belowThirties = function () {
    let result = [];
    for (let i = 0; i < this.length; i++) {
      let one = this[i];
      let today = new Date();
      if (
        new Date(one.dob) >
        new Date(today.getFullYear() - 30, today.getMonth(), today.getDate())
      ) {
        result.push(one);
      }
    }
    return result;
  };

  const navigate = useNavigate();

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();
  console.log(query);

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      setPage(1);
      handleSearch(word);
    }
  };
  useEffect(() => {
    let searchQuery = {};
    let title = query.get("title");
    setTotal(query.get("total"));
    let subtitle = query.get("subtitle");

    setTitle(title);
    setSubtitle(subtitle);

    if (title.split(" ")[1] === "yashash") {
      searchQuery = {
        livingStatus: subtitle.split(" ")[0],
      };
    } else {
      switch (title) {
        case "Talabalarning oilaviy holati":
          searchQuery = { marriageStatus: subtitle };
          break;
        case "Talabalarning ijtimoiy holati":
          switch (subtitle) {
            case "Kam ta'minlangan oila farzandlari (IHYaR)":
              searchQuery = { lowIncomeFamily: true };
              break;
            case "Temir daftardagi oila farzandlari":
              searchQuery = { ironNotebook: true };
              break;

            case "Yoshlar daftariga kiritilganlar":
              searchQuery = { inYouthBook: true };
              break;

            case "Ayollar daftariga kiritilgan ona farzandlari":
              searchQuery = { motherInYouthBook: true };
              break;

            case "Og'ir ijtimoiy holatga tushib qolgan oila farzandlari":
              searchQuery = { familyInHeavySocialStatus: true };
              break;

            case "TMehr daftariga kiritilgan talabalar":
              searchQuery = { mercyBook: true };
              break;
            case "Imtiyoz asosida o'qishga qabul qilinganlar":
              searchQuery = { withPrivilage: true };
              break;
            case "Muddatli harbiy xizmatdan qaytganlar":
              searchQuery = { returnedMilitary: true };
              break;
            case "Nafaqa oluvchilar":
              searchQuery = { pensioner: true };
              break;
            case "Ishlaydigan talabalar":
              searchQuery = { isWorking: true };
              break;
            case "Farzandli talabalar":
              searchQuery = { hasChild: true };
              break;
            case "Ota-onasi ota-onalik huquqidan mahrum etilganlar":
              searchQuery = { noParentalRights: true };
              break;
            case "Mehribonlik uyi va Bolalar shaharchasida tarbiyalangan yoshlar soni":
              searchQuery = { fromOrphanage: true };
              break;
            case "Nogironligi bo'lganlar":
              searchQuery = {
                $nor: [{ disabled: null }, { disabled: "" }],
              };
              break;
            case "Toifalari bo'yicha":
              searchQuery = {
                $or: [
                  { weakSight: true },
                  { movementInjured: true },
                  { weakDeaf: true },
                  { blind: true },
                  { deaf: true },
                  { speechDefect: true },
                  { otherDisabled: true },
                ],
              };
              break;
            case "Yetim talabalar soni":
              searchQuery = {
                $nor: [{ orphan: null }, { orphan: "" }],
              };
              break;
            default:
              searchQuery = {};
              break;
          }
          break;
        case "Iqtidorli talabalar":
          switch (subtitle) {
            case "Davlat mukofoti bilan taqdirlanganlar":
              searchQuery = { governmentAward: true };
              break;
            case "Ko'krak nishonlari bilan taqdirlanganlar":
              searchQuery = { badgeAward: true };
              break;
            case "Prezident stipendiyasi sohiblari":
              searchQuery = { presidentScholarship: true };
              break;
            case "Davlat stipendiyalari sohiblari":
              searchQuery = { governmentScholarship: true };
              break;
            case "Xalqaro stipendiyalar sohiblari":
              searchQuery = { internationalScholarship: true };
              break;
            case "Respublika sport musobaqalari g'oliblari":
              searchQuery = { republicSport: true };
              break;
            case "Xalqaro sport musobaqalari g'oliblari":
              searchQuery = { internationalSport: true };
              break;
            case "Respublika fan olimpiadalari g'oliblari":
              searchQuery = { republicScienceOlymp: true };
              break;
            case "Xalqaro fan olimpiadalari g'oliblari":
              searchQuery = { internationalScienceOlimp: true };
              break;
            case "Boshqa yutuq va imtiyozlar":
              searchQuery = { otherAwards: true };
              break;
            default:
              searchQuery = {};
              break;
          }
          break;
        case "Talabalarning moddiy rag'batlantirilish holati":
          switch (subtitle) {
            case "Xalqaro ta'lim dasturlari bo'yicha imtihon topshirganlar":
              searchQuery = { internationalEduProgsExam: true };
              break;
            case "Xalqaro ta'lim dasturlari bo'yicha imtihon xarajatlari qoplab berilganlar":
              searchQuery = { internationalEduProgsExamPaid: true };
              break;
            case `"Yoshlar daftari" jamg'armasidan to'lov-shartnoma mablag'i to'lab berilgan`:
              searchQuery = { contractPaidYouthBook: true };
              break;
            case "Mahalliy hokimliklar tomonidan to'lov-shartnoma mablag'i to'lab berilgan":
              searchQuery = { contractPaidLocalAuth: true };
              break;
            case "Oliy ta'lim muassasasi tomonidan moddiy rag'batlantirilganlar":
              searchQuery = { contractPaidUnivAward: true };
              break;
            case "Oliy ta'lim muassasasi tomonidan shartnoma sumnmasi to'lab berilganlar":
              searchQuery = { contractPaidUnivPaid: true };
              break;
            case "Homiylar va boshqa tashkilotlar tomonidan to'lov-shartnoma mablag'i to'lab berilgan":
              searchQuery = { contractPaidLocalAuthSponsors: true };
              break;
            case "Ijarada yashash uchun to'lov davlat tomonidan amalga oshirilganlar":
              searchQuery = { governmentRentPaid: true };
              break;
            case "TTJ da yashash bo'yicha imtiyozga ega":
              searchQuery = { rentPaid: true };
              break;
            default:
              searchQuery = {};
              break;
          }
          break;
        case "Huquqbuzarlik va jinoyatchilik bo'yicha alohida nazoratda turuvchi talabalar":
          switch (subtitle) {
            case "Notinch oiladagi yoshlar":
              searchQuery = { fromTroubledFamily: true };
              break;
            case "Yengil tabiatli xotin-qizlar":
              searchQuery = { lightNatureWoman: true };
              break;
            case "JIEMdan qaytganlar":
              searchQuery = { fromJail: true };
              break;
            case "Probatsiya hisobida turuvchilar":
              searchQuery = { inProbabition: true };
              break;
            case "Ma'muriy huquqbuzarlik sodir etganlar":
              searchQuery = { committedOffence: true };
              break;
            case "Tazyiq va zo'ravonlikdan jabrlanganligi sababli “himoya orderi” berilgan xotin-qizlar":
              searchQuery = { protectionOrder: true };
              break;
            case "O'z joniga qasd qilishga moyilligi bo'lganlar":
              searchQuery = { suicideTendency: true };
              break;
            case "Boshqalar":
              searchQuery = { specialControlOthers: true };
              break;
            default:
              searchQuery = {};
              break;
          }
          break;
        default:
          searchQuery = {};
          break;
      }
    }
    setSQuery(searchQuery);
    let isMounted = true;
    axios
      .post(
        `${
          user.role === "Tutor"
            ? `/api/students/${user.username}?per_page=50&page=${1}`
            : user.role === "Dekanat"
            ? `/api/dean-students/${user.username}?per_page=50&page=${1}`
            : `/api/students?per_page=50&page=${1}`
        }`,
        searchQuery,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        if (isMounted) {
          setData(result.data.data.belowThirties());
          setCount(result.data.count);
          setPages(result.data.pages);
          setLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);
  const handleSearch = () => {
    ///
    axios
      .post(
        `${
          user.role === "Tutor"
            ? `/api/students/${
                user.username
              }?search=${word}&per_page=50&page=${1}`
            : user.role === "Dekanat"
            ? `/api/dean-students/${
                user.username
              }?search=${word}&per_page=50&page=${1}`
            : tutor === ""
            ? `/api/students?${
                word === "" ? "" : `search=${word}&`
              }per_page=50&page=${1}`
            : `/api/students/${tutor}?${
                word === "" ? "" : `search=${word}&`
              }per_page=50&page=${1}`
        }`,
        sQuery,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setData(result.data.data);
        setCount(result.data.count);
        setPages(result.data.pages);
        setLoading(false);
      });
  };

  const handleLoadMore = () => {
    axios
      .post(
        `${
          user.role === "Tutor"
            ? `/api/students/${user.username}?${
                word === "" ? "" : `search=${word}&`
              }per_page=50&page=${page + 1}`
            : user.role === "Dekanat"
            ? `/api/dean-students/${user.username}?${
                word === "" ? "" : `search=${word}&`
              }per_page=50&page=${page + 1}`
            : tutor === ""
            ? `/api/students?${
                word === "" ? "" : `search=${word}&`
              }per_page=50&page=${page + 1}`
            : `/api/students/${tutor}?${
                word === "" ? "" : `search=${word}&`
              }per_page=50&page=${page + 1}`
        }`,
        sQuery,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setData([...data, ...result.data.data]);
        setCount(result.data.count);
        setPage(page + 1);
        setLoading(false);
      });
  };

  const handleDownload = () => {
    axios
      .post(
        `${
          user.role === "Tutor"
            ? `/api/download/students/${user.username}`
            : "/api/download/students"
        }`,
        { ...sQuery, title: subtitle },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "Talabalar.xlsx"; //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  const handleDownloadCsv = () => {
    axios
      .post(
        `${
          user.role === "Tutor"
            ? `/api/download-csv/students/${user.username}`
            : "/api/download-csv/students"
        }`,
        { ...sQuery, title: subtitle },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "Talabalar.csv"; //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Talabalar Ro'yxati</h1>
              <div className="text-zero top-right-button-container">
                {(user.role === "Admin" || user.role === "Tutor") && (
                  <Button
                    color="primary"
                    className="top-right-button"
                    onClick={handleDownload}
                  >
                    Excelda olish
                  </Button>
                )}
                {user.role === "Admin" && (
                  <Button
                    color="primary"
                    className="ml-2 top-right-button"
                    onClick={handleDownloadCsv}
                  >
                    Csvda olish
                  </Button>
                )}
              </div>
            </div>
            <div className="mb-2">
              ({title} &gt; {subtitle}) |&nbsp; Talabalar soni: {total}
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <div className="loading" hidden={!loading} />
          {!loading && data.length === 0 ? (
            <p>Ro'yxat bo'sh</p>
          ) : (
            data.map((student) => (
              <Colxx xxs="12" key={student._id} className="mb-3">
                <Card className="d-flex flex-row">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                      <NavLink
                        to={`/app/students/${student._id}`}
                        className="w-40 w-sm-100"
                      >
                        <p className="list-item-heading mb-1 truncate">
                          {`${student.name} ${student.surname}`}
                        </p>
                      </NavLink>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {`${student.educationMode} ${student.educationType}`}
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {student.educationYear}
                      </p>
                    </div>
                  </div>
                </Card>
              </Colxx>
            ))
          )}
          {!loading && data.length !== 0 && pages !== page && (
            <div className="col-12">
              <Button
                color="primary"
                className="top-right-button"
                onClick={handleLoadMore}
              >
                Ko'proq...
              </Button>
            </div>
          )}
        </Row>
      </div>
    </AppLayout>
  );
};

export default DashboardStudentsList;
