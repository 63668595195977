/* eslint-disable prefer-rest-params */
import React, { useEffect, useRef, useState } from "react";
import {
  Chart,
  LineController,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
} from "chart.js";

Chart.register(
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title
);

const LineDrawTime = ({ data }) => {
  const chartContainer = useRef(null);
  const [, setChartInstance] = useState(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      //   const context = chartContainer.current.getContext("2d");
      const context = chartContainer.current;
      const newChartInstance = new Chart(context, {
        type: "line",
        options: {
          plugins: {
            filler: {
              propagate: false,
              drawTime: "beforeDatasetsDraw",
            },
          },
          pointBackgroundColor: "#fff",
          radius: 10,
          interaction: {
            intersect: false,
          },
        },
        data,
      });
      setChartInstance(newChartInstance);
    }
  }, [chartContainer, data]);

  return <canvas ref={chartContainer} />;
};

export default LineDrawTime;
