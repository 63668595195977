import { CHART_COLORS, CHART_COLORS_ALPHA } from "./Constants";

export const marriageDataConstruct = (data) => {
  console.log(data);
  let titles = ["Turmush qurmagan", "Turmush qurgan", "Ajrashgan"];
  let result = [];
  let resultData = [];
  let resultWomenData = [];
  let labels = [];
  for (let i = 0; i < titles.length; i++) {
    labels.push(i + 1);
    const students = data.filter((one) => one.marriageStatus === titles[i]);
    const women = students.filter((one) => one.isFemale);
    resultData.push(students.length);
    resultWomenData.push(women.length);
    result.push({
      title: titles[i],
      total: students.length,
      women: women.length,
    });
    // eslint-disable-next-line default-case
  }
  return {
    data: result,
    chartData: {
      labels: labels,
      datasets: [
        {
          data: resultData,
          label: "Jami",
          borderColor: CHART_COLORS.blue,
          backgroundColor: CHART_COLORS_ALPHA.blue,
          borderWidth: 2,
          borderRadius: 5,
          borderSkipped: false,
        },
        {
          data: resultWomenData,
          label: "Ayollar",
          borderColor: CHART_COLORS.red,
          backgroundColor: CHART_COLORS_ALPHA.red,
          borderWidth: 2,
          borderRadius: 5,
          borderSkipped: false,
        },
      ],
      titles: titles,
    },
  };
};
