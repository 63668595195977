import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import Select from "react-select";

const initialValues = {
  username: "",
  password: "",
  role: "",
  firstName: "",
  lastName: "",
  faculty: "",
  position: "",
};

const AddUser = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState(initialValues);
  const [selectTutors, setSelectTutors] = useState([]);
  const [tutors, setTutors] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.password === "") {
      delete values.password;
    }
    let data = { ...values };
    if (values.role === "Dekanat") {
      data.managedTutors = tutors;
    }

    axios
      .post(`${isNew ? "/api/admin/register" : `/api/admin/${id}`}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        if (result.data.success) {
          navigate("/app/users");
        }
      })
      .catch((err) => {
        setErrorMessage(err.response.data);
      });
  };

  const handleDeleteUser = () => {
    axios
      .delete(`/api/admin/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.data.success) {
          navigate("/app/users");
        }
      });
  };

  const handleSelectChange = (e) => {
    setTutors([...tutors, e.value]);
  };

  const handleRemoveTutor = (index) => {
    let newTutors = [...tutors];
    newTutors.splice(index, 1);
    setTutors(newTutors);
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/api/admin/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          let { data } = result.data;
          if (data.role === "Dekanat") {
            setTutors(data.managedTutors);
          }
          setValues(data);
        });

    axios
      .get(`api/tutors`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        let { data: tutorData } = res.data;
        let tempOptions = [];
        tutorData.forEach((one) => {
          tempOptions.push({
            label: `${one.firstName} ${one.lastName}`,
            value: one.username,
          });
        });
        setSelectTutors(tempOptions);
        setLoading(false);
      });
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>{isNew ? "Yangi" : "Tahrirlash"}</h1>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="8">
                        <FormGroup row>
                          <Label sm="3">username</Label>
                          <Colxx sm="9">
                            <Input
                              type="text"
                              name="username"
                              value={values.username}
                              onChange={handleChange}
                            />
                          </Colxx>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label>Parol</Label>
                          <Input
                            type="text"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup md="4">
                          <Label>Role</Label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="role"
                            value={{
                              label: values.role,
                              value: values.role,
                            }}
                            onChange={(e) =>
                              setValues({ ...values, role: e.value })
                            }
                            options={[
                              {
                                label: "Foydalanuvchi",
                                value: "Foydalanuvchi",
                              },
                              { label: "Tutor", value: "Tutor" },
                              { label: "Dekanat", value: "Dekanat" },
                              { label: "Admin", value: "Admin" },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label>Ismi</Label>
                          <Input
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label>Familiyasi</Label>
                          <Input
                            type="text"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label>Lavozimi</Label>
                          <Input
                            type="text"
                            name="position"
                            value={values.position}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label>Fakultet</Label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="faculty"
                            value={{
                              label: values.faculty,
                              value: values.faculty,
                            }}
                            onChange={(e) =>
                              setValues({ ...values, faculty: e.value })
                            }
                            options={[
                              {
                                label: "Avtomatika va elektrotexnika",
                                value: "Avtomatika va elektrotexnika",
                              },
                              {
                                label: "Mashinasozlik texnologiyasi",
                                value: "Mashinasozlik texnologiyasi",
                              },
                              {
                                label: "Avtomobilsozlik",
                                value: "Avtomobilsozlik",
                              },
                              { label: "Iqtisodiyot", value: "Iqtisodiyot" },
                              {
                                label: "Transport va logistika",
                                value: "Transport va logistika",
                              },
                              { label: "Xalqaro", value: "Xalqaro" },
                              { label: "IB va KT", value: "IB va KT" },
                              {
                                label: "Sirtqi bo'lim",
                                value: "Sirtqi bo'lim",
                              },
                              {
                                label: "Magistratura bo'limi",
                                value: "Magistratura bo'limi",
                              },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {values.role === "Dekanat" && (
                      <>
                        <Row>
                          <Col md="4">
                            <div className="form-group has-float-label">
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                name="tutors"
                                onChange={(e) => handleSelectChange(e)}
                                options={selectTutors}
                              />
                              <span>Tutor</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          {tutors.map((tutor, index) => (
                            <Card key={index}>
                              <CardBody>
                                {tutor}
                                <i
                                  className="iconsminds-remove ml-2 clickable"
                                  onClick={() => handleRemoveTutor(index)}
                                />
                              </CardBody>
                            </Card>
                          ))}
                        </Row>
                      </>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div>
          <Button
            color="primary"
            className="top-right-button"
            onClick={() => navigate("/app/users")}
          >
            Orqaga
          </Button>
          &nbsp;
          {!isNew && (
            <Button
              color="primary"
              className="top-right-button"
              onClick={handleDeleteUser}
            >
              O'chirish
            </Button>
          )}
          &nbsp;
          <Button
            color="primary"
            className="top-right-button"
            onClick={handleSubmit}
          >
            {isNew ? "Qo'shish" : "Yangilash"}
          </Button>
        </div>
        &nbsp;
        <div>{errorMessage}</div>
      </div>
    </AppLayout>
  );
};

export default AddUser;
