import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import Select from "react-select";

import Countries from "../../../constants/countriesList/country";
import Cities from "../../../constants/countriesList/state";

let countriesList = [];
Countries.forEach((country) => {
  countriesList.push({ label: country.name, value: country.name });
});

const cities = () => {
  let cities = [];
  let tempCities = Cities.filter((city) => city.countryCode === "UZ");
  tempCities.forEach((city) => {
    cities.push({ label: city.name, value: city.name });
  });
  return cities;
};

const initialValues = {
  currency: "",
  locations: "",
  managingCountries: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  telegramUsername: "",
  gender: "",
  location: "",
  managedCountries: [],
};

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [currency, setCurrency] = useState("");
  const [locations, setLocations] = useState([]);
  const [managingCountries, setManagingCountries] = useState([]);
  const [preview, setPreview] = useState("");
  const [image, setImage] = useState({});
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const handleImage = (e) => {
    let file = e.target.files[0];
    setPreview(window.URL.createObjectURL(file));

    //resize
    Resizer.imageFileResizer(file, 720, 500, "JPEG", 100, 0, async (uri) => {
      try {
        let { data } = await axios.post(
          "/api/upload-image",
          {
            image: uri,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        //set image in te state
        setImage(data);
      } catch (err) {
        console.log(err);
      }
    });
  };

  const handleImageRemove = async () => {
    try {
      await axios.post(
        "/api/remove-image",
        { image },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setImage({});
      setPreview("");
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (e, data) => {
    e.preventDefault();

    axios
      .post(`/api/settings/update`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        if (result.data.success) {
          navigate("/app/settings");
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/settings/all`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        let { data } = result.data;
        data.forEach((element) => {
          if (element.name === "currency") {
            setCurrency(element.value);
          } else if (element.name === "locations") {
            setLocations(element.arrayItems);
          } else if (element.name === "managingCountries") {
            setManagingCountries(element.arrayItems);
          }
        });
        setLoading(false);
      });
  }, []);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Settings</h1>
            </div>

            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <>
            <Row>
              <Colxx xxs="12" className="mb-4">
                <Card className="mb-4">
                  <CardBody>
                    <Form>
                      <Row>
                        <Col md="8">
                          <FormGroup row>
                            <Label sm="3">USD rate</Label>
                            <Colxx sm="9">
                              <Input
                                type="number"
                                name="currency"
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                              />
                            </Colxx>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <Button
                            color="primary"
                            className="top-right-button"
                            onClick={(e) =>
                              handleSubmit(e, {
                                name: "currency",
                                value: currency,
                              })
                            }
                          >
                            Update
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>
            <Row>
              <Colxx xxs="12" className="mb-4">
                <Card className="mb-4">
                  <CardBody>
                    <Form>
                      <Row>
                        <Col md="8">
                          <FormGroup row>
                            <Label sm="3">Managed Countries</Label>
                            <Colxx sm="9">
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                isMulti
                                value={managingCountries}
                                onChange={(e) => {
                                  setManagingCountries(e);
                                }}
                                options={countriesList}
                              />
                            </Colxx>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <Button
                            color="primary"
                            className="top-right-button"
                            onClick={(e) =>
                              handleSubmit(e, {
                                name: "managingCountries",
                                arrayItems: managingCountries,
                              })
                            }
                          >
                            Update
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>
            <Row>
              <Colxx xxs="12" className="mb-4">
                <Card className="mb-4">
                  <CardBody>
                    <Form>
                      <Row>
                        <Col md="8">
                          <FormGroup row>
                            <Label sm="3">Managed Countries</Label>
                            <Colxx sm="9">
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                isMulti
                                value={locations}
                                onChange={(e) => {
                                  setLocations(e);
                                }}
                                options={cities()}
                              />
                            </Colxx>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <Button
                            color="primary"
                            className="top-right-button"
                            onClick={(e) =>
                              handleSubmit(e, {
                                name: "locations",
                                arrayItems: locations,
                              })
                            }
                          >
                            Update
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>
          </>
        )}
      </div>
    </AppLayout>
  );
};

export default Settings;
