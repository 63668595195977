import React from "react";
import { Card, CardBody, CardTitle, Row } from "reactstrap";
import Radar from "components/charts/Radar";

const CategoriesRadar = ({
  chartClass = "chart-container",
  title,
  data,
  child,
}) => {
  return (
    <Card>
      <CardBody>
        <CardTitle>{title}</CardTitle>
        <div className={"mb-5"}>
          <Radar data={data.chartData} />
        </div>
        <Row>{child}</Row>
      </CardBody>
    </Card>
  );
};

export default CategoriesRadar;
